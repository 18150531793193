import React from 'react'
import PropTypes from 'prop-types'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import useAxios from 'axios-hooks'
import useStore from '../../lib/store'

import Markdown from '../Markdown/Markdown';


// i18n
import { useTranslation } from 'react-i18next';
//

import './TabbedMDComponent.scss'
import CloseButton from '../CloseButton/CloseButton';

const TabContent = props => {
  const { lang, section, code } = props;
  const [{ data, loading, error }/*, refetch*/] = useAxios(
    `/pages/${section}/${code}.${lang}.md`
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error!</p>

  return (
    <div className="TabbedContent"><Markdown>{data}</Markdown></div>
  );
}

const TabbedMDComponent = props => {
  const { t, i18n } = useTranslation();
  const { tabs, section } = props;
  const rightPanel = useStore(state => state.rightPanel);
  const setRightPanel = useStore(state => state.setRightPanel);

  return (
    <div className="TabbedMDComponent">
      <CloseButton />
      <h1>{t("components.TabbedMDComponent.title")}</h1>
      <Tabs
        activeKey={rightPanel.id}
        onSelect={k => setRightPanel({...rightPanel, id: k})}
        className="mb-3"
        unmountOnExit={true}
      >
      {tabs.map(tab => (
        <Tab key={tab.code} eventKey={tab.code} title={
          <div className='PedroTab'>
            {tab.text}
          </div>
        }>
          <TabContent lang={i18n.language} section={section} code={tab.code} />
        </Tab>
      ))}
    </Tabs>

    </div>
  )
}

TabbedMDComponent.propTypes = {
  /**
   * tabs: an object like this :
   *   const tabs = [
   *    { code: 'interface', text: t("components.Help.interface.title")},
   *    { code: 'when', text: t("components.Help.when.title")},
   *    { code: 'what', text: t("components.Help.what.title")},
   *    { code: 'where', text: t("components.Help.where.title")},
   *    { code: 'others', text: t("components.Help.others.title")},
   *    { code: 'map', text: t("components.Help.map.title")},
   *  ];
   */
  tabs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  /**
   * section: a string ('help')
   */
  section: PropTypes.string.isRequired,
}

export default TabbedMDComponent
