import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types'
import maplibregl from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'

import './MiniMap.scss'

import { basemaps } from '../../lib/map/basemaps';
import { initClusters, loadClusters, loadSpiderfy, unloadCluster } from '../../lib/map/clusters';
import { map } from 'underscore';

const toBBox = require('geojson-bounding-box');


function MiniMap(props) {
  const mapContainer = useRef(null);
  const rmap = useRef(null);
  const { geojson, minimaptype } = props;

  //console.log("geojson: ", geojson);

  const initMap = () => {
    const map = rmap.current = new maplibregl.Map({
      container: mapContainer.current,
      style: minimaptype == "site" ? basemaps['ESRIMapTiles'].url : basemaps['Positron'].url,
      attributionControl: false,
      renderWorldCopies: false,
      //transformRequest: transformRequest,
    });
    initClusters(map);

    map.addControl(new maplibregl.AttributionControl({
      compact: false
    }), 'bottom-right');

    /**
     * Add Scale Bar (echelle)
     */
    let scale1 = new maplibregl.ScaleControl({
      maxWidth: 100,
      unit: 'imperial'
    });
    map.addControl(scale1, 'bottom-right');
    //scale1.setUnit('metric');

    /**
     * Add Scale Bar (echelle)
     */
    let scale2 = new maplibregl.ScaleControl({
      maxWidth: 100,
      unit: 'metric'
    });
    map.addControl(scale2, 'bottom-right');
    //scale2.setUnit('metric');


    //map.dragRotate.disable();
    //map.touchZoomRotate.disableRotation();
    map.addControl(new maplibregl.NavigationControl({
      showCompass: true,
    }), 'bottom-right');


    const onLoadForDataset = () => {
      if (geojson) {

        const bbox = toBBox(geojson);
        //console.log("bbox: ", bbox);
        map.fitBounds(bbox, { padding: {top: 50, bottom: 50, left: 50, right: 50}});

        try {
          map.addSource(`thegeojson`, {
            type: 'geojson',
            data: geojson,
          });
        } catch (err) {
          console.log("minimap thegeojson add source error : ", err);
        }

        map.addLayer({
          id: `fill-thegeojson`,
          type: 'fill',
          source: `thegeojson`,
          filter: ['==', '$type', 'Polygon'],
          layout: {
          },
          paint: {
            'fill-color': '#0080ff', // blue color fill
            'fill-opacity': 0.3,
          }
        })

        map.addLayer({
          'id': `outline-thegeojson`,
          'type': 'line',
          'source': `thegeojson`,
          'layout': {},
          //filter: ['==', '$type', 'LineString'],
          'paint': {
            'line-color': '#000',
            'line-width': 3
          }
        });
      }
    }

    const onLoadForSite = () => {
      //loadClusters(map, geojson);

      const bbox = toBBox(geojson);
      map.flyTo({
        center: [ bbox[0], bbox[1]],
        zoom: 15,
        speed: 5,
      })

      map.addSource('arkeosites', {
        'type': 'geojson',
        'data': geojson,
        'attribution': '<a href="?p=right&rp=project§credits">Made by FiNi 2024</a>',
      });

      map.addLayer({
        'id': 'arkeosite_circle',
        'type': 'symbol',
        'source': 'arkeosites',
        layout: {
          'icon-image': [
            'match',
            ['get', 'exceptional'],
            'yes',
            'arkeo-disc-exceptional', // image if exceptional
            'arkeo-disc', // image if not exceptional
          ],
          //'icon-image': 'arkeo-disc',
          'icon-allow-overlap': true, // recommended
        },
        'paint': {
          'icon-color': [ 'get', 'color' ],
          //'icon-color': [ ...chronos.reduce((result, chrono) => { result.push(chrono.mag); result.push(chrono.color); return result; }, ['case']), '#ffffff'],
        },
      });

    }

    map.on('load', e => {
      if (minimaptype == "dataset") {
        onLoadForDataset();
      } else if (minimaptype == "site") {
        onLoadForSite();
      }
    });

  }

  /**
   * This useEffect will create the map when we get the map data.
   */
  useEffect(() => {
    if (rmap.current) {
      rmap.current.remove();
      rmap.current=undefined;
    }
    initMap();
  }, [geojson]);

  /**
   * This useEffect will destroy the map instance when the component is unmounted.
   */
  useEffect(() => {
    return () => {
      if (rmap.current) {
        rmap.current.remove();
        rmap.current=undefined;
      }
    }
  }, []);

  return (
    <div className="minimap-container-container">
      <div ref={mapContainer} className="minimap-container" />
    </div>
  )
}

MiniMap.propTypes = {
  geojson: PropTypes.object,
  minimaptype:  PropTypes.string.isRequired,
}

export default MiniMap
