// react
import React from 'react';
//import PropTypes from 'prop-types';
//

// zustand
import useStore, { useSearchStore } from '../../lib/store'
import {shallow} from 'zustand/shallow'
//

// bootstrap
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
//import Dropdown from 'react-bootstrap/Dropdown';
//import DropdownButton from 'react-bootstrap/DropdownButton';
//

// i18n
import { useTranslation } from 'react-i18next';
//

import ChronologySelector from '../ChronologySelector/ChronologySelector';
import ChronologyMenu from './ChronologyMenu';
import FlatSelect from '../FlatSelect/FlatSelect';

import './FormSearchWhen.scss';

import { yearToHumanNumber, HumanNumberToComputerYear, UNDETERMINED_LEFT, UNDETERMINED_RIGHT } from '../../lib/year';

FormSearchWhen.propTypes = {

};

/**
 * The "When" Tab in the Search Form (left side bar)
 * @param {*} props
 * @returns The 'When' Component
 */
function FormSearchWhen() {
  const { t, i18n } = useTranslation();
  const selectedChronologyId = useSearchStore(state => state.selectedChronologyId);
  const [
    chronologyStartDate, setChronologyStartDate,
    chronologyEndDate, setChronologyEndDate,
    chronologyFindIncludeUndetermined, setChronologyFindIncludeUndetermined,
    chronologyFindOnlyInside, setChronologyFindOnlyInside,
  ] = useSearchStore(state => [
    state.chronologyStartDate, state.setChronologyStartDate,
    state.chronologyEndDate, state.setChronologyEndDate,
    state.chronologyFindIncludeUndetermined, state.setChronologyFindIncludeUndetermined,
    state.chronologyFindOnlyInside, state.setChronologyFindOnlyInside,
  ], shallow);
  const chronologies = useStore(state => state.chronologies);

  const selectedChronology = chronologies.find(c => c.id === selectedChronologyId);

  return (
    <div className="FormSearchWhen">
      <Form.Label>
        {t("components.FormSearchWhen.label")}
      </Form.Label>

      <ChronologySelector/>

      <Form.Label>
        {t("components.FormSearchWhen.chronology.period.label")}
      </Form.Label>

      {selectedChronology ? (
      <ChronologyMenu
        chronologies={chronologies}
        chronology={selectedChronology}
        lang={i18n.language}
        path={[selectedChronology.id]}
        //disabled={chronologyStartDate !== UNDETERMINED_LEFT || chronologyEndDate !== UNDETERMINED_RIGHT}
      />
      ) : 'Loading...'}

      <Form.Label>
        {t("components.FormSearchWhen.period.label")}
      </Form.Label>

      <div className="periodselector">
        <FormControl
          type="number"
          placeholder={t("components.FormSearchWhen.period-start.label")}
          step={1}
          value={yearToHumanNumber(chronologyStartDate)}
          onChange={e => setChronologyStartDate(HumanNumberToComputerYear(e.target.value, UNDETERMINED_LEFT))}
        />
        <FormControl
          type="number"
          placeholder={t("components.FormSearchWhen.period-end.label")}
          step={1}
          value={yearToHumanNumber(chronologyEndDate)}
          onChange={e => setChronologyEndDate(HumanNumberToComputerYear(e.target.value, UNDETERMINED_RIGHT))}
        />
      </div>

      <Form.Label>
        {t("components.FormSearchWhen.chronology.include.label")}
      </Form.Label>

      <FlatSelect
        label={t("components.FormSearchWhen.chronology.include.label")}
        leaf={false}
        unselectable={true}
      >
        <FlatSelect
          label={t('components.FormSearchWhen.chronology.include.undetermined.label')}
          leaf={true}
          onSelect={_sel => setChronologyFindIncludeUndetermined(_sel)}
          selected={chronologyFindIncludeUndetermined}
        />
        <FlatSelect
          label={t('components.FormSearchWhen.chronology.include.only-inside.label')}
          leaf={true}
          onSelect={() => setChronologyFindOnlyInside(true)}
          selected={chronologyFindOnlyInside === true}
          showRadio={true}
        />
        <FlatSelect
          label={t('components.FormSearchWhen.chronology.include.not-only-inside.label')}
          leaf={true}
          onSelect={() => setChronologyFindOnlyInside(false)}
          selected={chronologyFindOnlyInside === false}
          showRadio={true}
        />
      </FlatSelect>
    </div>
  );
}

export default FormSearchWhen;
