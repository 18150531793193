import React from 'react'
import PropTypes from 'prop-types'

import useAxios from 'axios-hooks'

import Markdown from '../Markdown/Markdown';


// i18n
import { useTranslation } from 'react-i18next';
//

import './PagesProject.scss'
import CloseButton from '../CloseButton/CloseButton';

const PagesProject = props => {
  const { /*t, */i18n } = useTranslation();
  const { code } = props;

  const [{ data, loading, error }/*, refetch*/] = useAxios(
    `/pages/project/${code}.${i18n.language}.md`
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error!</p>

  return (
    <div className="PagesProject">
      <CloseButton />

      <Markdown>{data}</Markdown>

      <br />
      <hr />
      <div className="logos">
        <img src='/logos/ministere-recherche.png' alt="Ministère de l'enseignement supérieur et de la recherche"/>
        <img src='/logos/Signature_Universite_Strasbourg_Unistra2_Transparent 1.png' alt="Université de Strasbourg Unistra2"/>
        <img src='/logos/11_Logo UMR 1.png' alt='UMR'/>
        <img src='/logos/logo-misha-court 1.png' alt='Misha'/>
        <img src='/logos/logo-MASA.png' alt='MASA'/>
        <img src='/logos/logo huma-num 1.png' alt='Huma-Num'/>
      </div>

    </div>
  )
}

PagesProject.propTypes = {
  /**
   * code: the code name of the md page ('intro', ...)
   */
  code: PropTypes.string.isRequired,
}

export default PagesProject
