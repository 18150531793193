import React from 'react'
//import PropTypes from 'prop-types'
import ShareButton from '../ShareButton/ShareButton'

import { GetChronologyDetails } from '../../lib/queries/chronology'

import { useQuery } from '@apollo/client';

import Linkify from 'react-linkify';

import useStore from '../../lib/store'

// i18n
import { useTranslation } from 'react-i18next';
import { getInLang } from '../../lib/translate';

import './PanelChronology.scss';
import Loader from '../Loader/Loader';
import MiniMap from '../MiniMap/MiniMap';
import Markdown from '../Markdown/Markdown'
import { yearToHuman } from '../../lib/year';
import CloseButton from '../CloseButton/CloseButton';

const myLink = (decoratedHref, decoratedText, key) => {
  return (
    <a href={decoratedHref} key={key} target='_blank' rel="noreferrer">
      {decoratedText}
    </a>
  );
};

const PanelChronology = props => {
  const { t, i18n } = useTranslation();
  const { id } = props;

  const setRightPanel = useStore(state => state.setRightPanel);

  const chronology = useQuery(GetChronologyDetails, {
    variables: {
      chronology_id: id,
    }
  }) // chronology = { loading, error, data }

  if (chronology.loading) return <div className="PanelChronology"><Loader /></div>
  if (chronology.error) return <div className="PanelChronology"><div className="Error">{chronology.error.message}</div></div>

  if (!chronology.data.ako_chronology_root || chronology.data.ako_chronology_root.length === 0)
    return <div className="PanelChronology"><div className="Error">Chronology not found</div></div>

  const cr = chronology.data.ako_chronology_root[0];
  //console.log("chronology data !", cr);

  const geojson = {
    "type": "FeatureCollection",
    "features": [{
      "type": "Feature",
      "geometry": cr.geom,
    }]
  };

  //console.log("geojson: ", geojson);

  return (
    <div className="PanelChronology">
      <MiniMap geojson={geojson}/>
      <CloseButton rp={{type: 'legend', id: 'when'}} />
      <div className="PanelChronologyContent">
        <div className="PanelChronologyHeader">
          <div className="HeaderTitle">{getInLang(cr.chronology.chronology_trs, i18n.language).name}</div>
          <div className="HeaderButtons">
            <ShareButton />
          </div>
        </div>

        <div className="TwoColumns">
          <div className="LeftColumn">
            <h5>{t`components.PanelChronology.temporal.title`}</h5>
            <table className="Chronos">
              <tbody>
                <tr>
                  <th>{t`components.PanelChronology.temporal.begin.title`}</th>
                  <td className="date">{yearToHuman(cr.chronology.start_date)}</td>
                  <td className="chrononame">{getInLang(cr.chronology.chronologies[0].chronology_trs, i18n.language).name}</td>
                  <td><div className="chronocolor" style={{ backgroundColor: '#'+cr.chronology.chronologies[0].color}}></div></td>
                </tr>
                <tr>
                  <th>{t`components.PanelChronology.temporal.end.title`}</th>
                  <td className="date">{yearToHuman(cr.chronology.end_date)}</td>
                  <td className="chrononame">{getInLang(cr.chronology.chronologies.at(-1).chronology_trs, i18n.language).name}</td>
                  <td><div className="chronocolor" style={{ backgroundColor: '#'+cr.chronology.chronologies.at(-1).color}}></div></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="RightColumn">
            <h5>{t`components.PanelChronology.geographical.title`}</h5>
            {getInLang(cr.chronology_root_trs, i18n.language).geographical_covering}
          </div>
        </div>

        <hr />

        <div className="TwoColumns">
          <div className="LeftColumn">
            <div><strong>{t`components.PanelChronology.authors.title`}</strong> : {cr.group.user__groups.map(ug => `${ug.user.lastname} ${ug.user.firstname}`).join(', ')}</div>
            <div><strong>{t`components.PanelChronology.editors.title`}</strong> : {cr.editor_uri ? <a href={cr.editor_uri} target='_blank'>{cr.editor}</a> : cr.editor}</div>
          </div>
          <div className="RightColumn">
            <div><strong>{t`components.PanelChronology.date-created.title`}</strong> : {new Date(cr.chronology.created_at).toLocaleDateString(i18n.language)}</div>
            <div><strong>{t`components.PanelChronology.date-updated.title`}</strong> : {new Date(cr.chronology.updated_at).toLocaleDateString(i18n.language)}</div>
          </div>
        </div>

        <hr />

        <div>
          <strong>{t`components.PanelChronology.description.title`}</strong> : <div style={{whiteSpace: 'pre-wrap'}}><Markdown>{getInLang(cr.chronology.chronology_trs, i18n.language).description}</Markdown></div>
        </div>

        <hr />

        <div>
          <div><strong>{t`components.PanelChronology.datasets.title`}</strong> :</div>
          {cr.databases.map(database => (
            <div key={database.id}>
              <a href='/#' onClick={
                e => {
                  e.preventDefault();
                  setRightPanel({type: 'db', id: database.id})
                }
              }>{database.name}</a> : {database.database__authors.map(author => `${author.user.lastname} ${author.user.firstname}`).join(', ')}
            </div>
          ))}
        </div>

        <hr />

        <div>
          <strong>{t`components.PanelChronology.Citation.title`}</strong> : <Linkify componentDecorator={myLink}>{cr.credits}</Linkify>
        </div>

        <div>
          <strong>{t`components.PanelChronology.ExternalLink.title`}</strong> :
          {' '}
          {t`components.PanelChronology.ExternalLink.leftpart`}
          {' '}
          <a href="https://client.perio.do/?page=authority-view&backendID=web-https%3A%2F%2Fdata.perio.do%2F&authorityID=p09hq4n" target='_blank' rel="noreferrer">Periodo</a>
          {' '}
          {t`components.PanelChronology.ExternalLink.rightpart`}
        </div>

        <div>
          <strong>{t`components.PanelChronology.license.title`}</strong> : <a href="https://opendatacommons.org/norms/odc-by-sa/" target="_blank" rel="noreferrer">Open Data Commons - ODC Attribution-Sharealike Community Norms</a>
        </div>

      </div>
    </div>
  )
}

PanelChronology.propTypes = {}

export default PanelChronology
