import maplibregl from 'maplibre-gl'
import _ from 'underscore';
import useStore, { useValidatedSearchStore } from '../store'

import config from '../../config.json';

//import MapboxglSpiderifier from 'mapboxgl-spiderifier'
//import Spiderfy from '@nazka/map-gl-js-spiderfy';
import Spiderfy from '../map-gl-js-spiderfy/src/index';

let _hack=0;
let _spiderfy = null;
let _events = {
  markers_data: null,
  markers_move: null,
  markers_moveend: null,
  site_click: null,
}

// objects for caching and keeping track of HTML marker objects (for performance)
let markers = {};
let markersOnScreen = {};

export const toFlatChrono = (chronology, flat=[]) => {
  chronology.chronologies.forEach(chrono => {
    toFlatChrono(chrono, flat);
    flat.push(chrono);
  });
  return flat;
}

const toFlatChronol1 = (chronology, flat=[]) => {
  chronology.chronologies.forEach(chrono => {
    //toFlatChrono(chrono, flat);
    flat.push(chrono);
  });
  return flat;
}

const buildArkeoCluster = () => {
  const chronologies = useStore.getState().chronologies;
  const selectedChronologyId = useValidatedSearchStore.getState().selectedChronologyId;
  const selectedChronology = chronologies.find(c => c.id === selectedChronologyId);
  const flatChronology = toFlatChronol1(selectedChronology);

  //console.log("buidling for chrono : ", selectedChronologyId);

  const chronos = [];
  flatChronology.forEach(p => {
    chronos.push({
      color: '#'+p.color,
      mag: ['==', ['get', 'periodeid'], p.id],
    });
  })
  chronos.push({
    color: config.chronology.undetermined.color, // undetermined
    mag: ['==', ['get', 'periodeid'], config.chronology.undetermined.id],
  });
  chronos.push({
    color: config.chronology.outside.color, // outside of chronology
    mag: ['==', ['get', 'periodeid'], config.chronology.outside.id],
  });
  return chronos;
}

export const unloadCluster = (map) => {
  if (_events.markers_data) { map.off('data', _events.markers_data); _events.markers_data = null; }
  if (_events.markers_move) { map.off('move', _events.markers_move); _events.markers_move = null; }
  if (_events.markers_moveend) { map.off('moveend', _events.markers_moveend); _events.markers_moveend = null; }
  if (_events.site_click) { map.off('click', _events.site_click); _events.site_click = null; }
  _.forEach(markers, marker => marker.remove());
  if (map.getLayer('arkeosite_cluster')) {
    map.removeLayer('arkeosite_cluster');
  }
  if (map.getLayer('arkeosite_circle')) {
    map.removeLayer('arkeosite_circle');
  }
  if (map.getSource('arkeosites')) {
    map.removeSource('arkeosites');
  }
  //const features = map.querySourceFeatures('arkeosites');
  //console.log("unloadCluster features: ", features);
  markers={};
  markersOnScreen = {};
  //console.log("unloadCluster...")

  if (_spiderfy) {
    _spiderfy.destruct();
    _spiderfy = null;
  }
  //console.log("markers", markers);
}

export const loadClusters = (map, geojson_result) => {
  let hack = ++_hack;

  let chronos = buildArkeoCluster();
  //console.log("load clusters...", hack, chronos.length);

  const clusterProperties = _.object(
    chronos.map((chrono, i) => `mag${i}`),
    chronos.map((chrono, i) => ['+', ['case', chrono.mag, 1, 0]])
  );

  //console.log("clusterProperties", clusterProperties);

  // add a clustered GeoJSON source for a sample set of arkeosites
  map.addSource('arkeosites', {
    'type': 'geojson',
    'data': geojson_result,
    'cluster': true,
    'clusterRadius': 60,
    'clusterMaxZoom': 22,
    'clusterProperties': clusterProperties,
    'attribution': '<a href="?p=right&rp=project§credits">Made by FiNi 2024</a>',
  });

  // circle and symbol layers for rendering individual arkeosites (unclustered points)

  /*
  console.log("circle-color",
    [ ...chronos.reduce((result, chrono) => { result.push(chrono.mag); result.push(chrono.color); return result; }, ['case']), '#ffffff']
  )
  */

  map.addLayer({
    'id': 'arkeosite_cluster',
    'type': 'symbol', // must be symbol
    'source': 'arkeosites',
    'filter': ['==', 'cluster', true],
    //'filter': ['ALL', ['cluster', true], ['<', ['zoom'], 4]],
    layout: {
      /*
      'icon-image': [
        'match',
        ['get', 'exceptional'],
        'yes',
        'arkeo-disc-exceptional', // image if exceptional
        'arkeo-disc', // image if not exceptional
      ],
      */
      'icon-image': 'arkeo-disc',
      'icon-allow-overlap': true, // recommended
    },
    'paint': {
      'icon-color': 'transparent'
      //'icon-color': 'red',
      //'icon-color': [ 'get', 'color' ],
      //'icon-color': [ ...chronos.reduce((result, chrono) => { result.push(chrono.mag); result.push(chrono.color); return result; }, ['case']), '#ffffff'],
    }

  });

  map.addLayer({
    'id': 'arkeosite_circle',
    'type': 'symbol',
    'source': 'arkeosites',
    'filter': ['!=', 'cluster', true],
    //'filter': ['ALL', ['cluster', true], ['<', ['zoom'], 4]],
    layout: {
      'icon-image': [
        'match',
        ['get', 'exceptional'],
        'yes',
        'arkeo-disc-exceptional', // image if exceptional
        'arkeo-disc', // image if not exceptional
      ],
      //'icon-image': 'arkeo-disc',
      'icon-allow-overlap': true, // recommended
    },
    'paint': {
      'icon-color': [ 'get', 'color' ],
      //'icon-color': [ ...chronos.reduce((result, chrono) => { result.push(chrono.mag); result.push(chrono.color); return result; }, ['case']), '#ffffff'],
    },
/*
    'paint': {
      'circle-color': [ ...chronos.reduce((result, chrono) => { result.push(chrono.mag); result.push(chrono.color); return result; }, ['case']), '#ffffff'],
      //'circle-color': 'white',
      'circle-opacity': 1,
      'circle-radius': 18,
    },
    */
  });

  /*
  map.addLayer({
    'id': 'arkeosite_label',
    'type': 'symbol',
    'source': 'arkeosites',
    'filter': ['!=', 'cluster', true],
    'layout': {
      'text-field': [
        'number-format',
        ['get', 'mag'],
        { 'min-fraction-digits': 1, 'max-fraction-digits': 1 }
      ],
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-size': 10
    },
    'paint': {
      'text-color': [
        'case',
        ['<', ['get', 'mag'], 0],
        'black',
        'white'
      ]
    }
  });
  */

  //_spiderfy.applyTo('arkeosite_cluster');

  /*
  setTimeout(() => {
    console.log("_spiderfy...")
    _spiderfy.applyTo('arkeosite_cluster');
  }, 5000);
  */






  function updateMarkers() {
    //console.log("update markers...", hack, chronos.length);
    if (hack != _hack) return;

    const newMarkers = {};
    const features = map.querySourceFeatures('arkeosites');
    //console.log("arkeosites features len : ", features.length, chronos.length);

    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    for (const feature of features) {
      const coords = feature.geometry.coordinates;
      const props = feature.properties;
      if (!props.cluster) continue;
      const id = props.cluster_id;

      let marker = markers[id];
      if (!marker) {
        const el = createDonutChart(props);
        marker = markers[id] = new maplibregl.Marker({
          element: el
        }).setLngLat(coords);
      }
      newMarkers[id] = marker;

      if (!markersOnScreen[id]) marker.addTo(map);
    }

    // for every marker we've added previously, remove those that are no longer visible
    for (const id in markersOnScreen) {
      if (!newMarkers[id]) markersOnScreen[id].remove();
    }
    markersOnScreen = newMarkers;
  }

  // after the GeoJSON data is loaded, update markers on the screen and do so on every map move/moveend
  if (_events.markers_data) { map.off('data', _events.markers_data); _events.markers_data = null; }
  map.on('data', _events.markers_data = e => {
    //console.log("data evenet ",e.sourceId, e.isSourceLoaded);

    // disabled on 2022-12-07, because sometimes, when loading a big shapefile, the event is not triggered for arkeosites
    // this may be a maplibre related bug
    if (e.sourceId !== 'arkeosites' || !e.isSourceLoaded || !e.tile) return;

    //console.log("data evenet ",e);

    if (_events.markers_data) { map.off('data', _events.markers_data); _events.markers_data = null; }

    //console.log("updateMarkers...");

    updateMarkers();

    if (_events.move) { map.off('move', _events.move); _events.move = null; }
    if (_events.moveend) { map.off('moveend', _events.moveend); _events.moveend = null; }

    map.on('move', _events.move = () => updateMarkers());
    map.on('moveend', _events.moveend = () => updateMarkers());

    //console.log('loadSpiderfy', hack, _hack)
    loadSpiderfy(map);
  });

  /*
  // this is another way to try to resolve the bug of event not triggered
  setTimeout(() => {
    let src = map.getSource("arkeosites");
    console.log("src", src);
    updateMarkers();
  }, 0);
  */

  // code for creating an SVG donut chart from feature properties
  function createDonutChart(props) {
    const offsets = [];
    const counts = _.values(_.pick(props, (val, key) => key.startsWith('mag')))

    //if (counts.length != chronos.length) console.log("counts.length != chronos.length", counts.length, chronos.length);
    //else console.log("COUNTS OK");

    let total = 0;
    for (const count of counts) {
      offsets.push(total);
      total += count;
    }
    let fontSize =
      total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
    let r = total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
    let r0 = Math.round(r * 0.6);
    let w = r * 2;

    let html =
    '<div><svg width="' +
    w +
    '" height="' +
    w +
    '" viewbox="0 0 ' +
    w +
    ' ' +
    w +
    '" text-anchor="middle" style="font: ' +
    fontSize +
    'px sans-serif; display: block">';

    //for (let i = 0; i < Math.min(counts.length, chronos.length); i++) {
    for (let i = 0; i < counts.length; i++) {
        html += donutSegment(
        offsets[i] / total,
        (offsets[i] + counts[i]) / total,
        r,
        r0,
        chronos[i].color
      );
    }
    html +=
    '<circle cx="' +
    r +
    '" cy="' +
    r +
    '" r="' +
    r0 +
    '" fill="white" /><text dominant-baseline="central" transform="translate(' +
    r +
    ', ' +
    r +
    ')">' +
    total.toLocaleString() +
    '</text></svg></div>';

    let el = document.createElement('div');
    el.innerHTML = html;
    return el.firstChild;
  }

  function donutSegment(start, end, r, r0, color) {
    if (end - start === 1) end -= 0.00001;
    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (end - 0.25);
    const x0 = Math.cos(a0),
          y0 = Math.sin(a0);
    const x1 = Math.cos(a1),
          y1 = Math.sin(a1);
    const largeArc = end - start > 0.5 ? 1 : 0;

    return [
      '<path d="M',
      r + r0 * x0,
      r + r0 * y0,
      'L',
      r + r * x0,
      r + r * y0,
      'A',
      r,
      r,
      0,
      largeArc,
      1,
      r + r * x1,
      r + r * y1,
      'L',
      r + r0 * x1,
      r + r0 * y1,
      'A',
      r0,
      r0,
      0,
      largeArc,
      0,
      r + r0 * x0,
      r + r0 * y0,
      '" fill="' + color + '" />'
    ].join(' ');
  }


  const getSiteIdFromEvent = e => {
    const bbox = [
      [e.point.x - 2, e.point.y - 2],
      [e.point.x + 2, e.point.y + 2]
    ];
    // Find features intersecting the bounding box.
    const selectedFeatures = map.queryRenderedFeatures(bbox, {
      //layers: ['counties']
    });
    /*
    const fips = selectedFeatures.map(
      (feature) => feature.properties.FIPS
    );
    */
    //console.log("selectedFeatures: ", selectedFeatures);

    const found = selectedFeatures.find(f => 'site_id' in f.properties)
    if (found) {
      return found.properties.site_id;
    }
    return false;
  }

  if (_events.site_click) { map.off('click', _events.site_click); _events.site_click = null }
  map.on('click', _events.site_click = e => {
    const site_id = getSiteIdFromEvent(e);
    if (site_id !== false) {
      useStore.setState({
        sidePanelOpened: 'right',
        rightPanel: { type: 'site', id: site_id },
      })
    }
  });

  /*
  map.on('mouseenter', 'arkeosite_circle', () => {
    map.getCanvas().style.cursor = 'pointer'
  })
  map.on('mouseleave', 'arkeosite_circle', () => {
    map.getCanvas().style.cursor = ''
  })
  */

}

export const loadSpiderfy = map => {
  /****************************************/
  /*              spider                  */
  /****************************************/
  //console.log("new spiderfy...");

  _spiderfy = new Spiderfy(map, {
    onLeafClick: f => console.log(f),
    closeOnLeafClick: false,
    minZoomLevel: 12,
    zoomIncrement: 1,
    spiderLegsWidth: 3,
    spiderLegsColor: '#ffffff',
    renderMethod: 'flat',
    spiralOptions: {
      legLengthStart: 45,
      legLengthFactor: 1.75,
      leavesSeparation: 30,
      leavesOffset: [0, 0],
    },
    spiderLeavesPaint: {
      'icon-color': [ 'get', 'color' ],
      //'icon-color': [ ...chronos.reduce((result, chrono) => { result.push(chrono.mag); result.push(chrono.color); return result; }, ['case']), '#ffffff'],
    },/*
    spiderLeavesPaint: {
      'circle-color': [ ...chronos.reduce((result, chrono) => { result.push(chrono.mag); result.push(chrono.color); return result; }, ['case']), '#ffffff'],
      'circle-stroke-color': '#ffffff',
      'circle-stroke-opacity': [
        'match',
        ['get', 'exceptional'],
        'yes',
        1, // image if exceptional
        0, // image if not exceptional
      ],
      'circle-stroke-width': 2,
    },*/
  });
  _spiderfy.applyTo('arkeosite_cluster');
}

export const initClusters = (map) => {
  const p1 = new Promise((resolve, reject) => {
    map.loadImage(
      '/icons-maps/map-disc-36.png',
      (error, image) => {
        if (error) reject(error);
        const newerr = map.addImage('arkeo-disc', image, { sdf: true });
        if (newerr) { console.log("addImage(arkeo-disc) newerr: ", newerr); reject(newerr); }
        else { resolve() };
      }
    );
  });

  const p2 = new Promise((resolve, reject) => {
    map.loadImage(
      '/icons-maps/map-disc-exceptional2-36.png',
      (error, image) => {
        if (error) reject(error);
        const newerr = map.addImage('arkeo-disc-exceptional', image, { sdf: true });
        if (newerr) { console.log("addImage(arkeo-disc-exceptional) newerr: ", newerr); reject(newerr); }
        else { resolve() };
      }
    );
  });

  return Promise.all([p1, p2]);
}
