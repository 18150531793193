import React from 'react';

// zustand
import useStore from '../../lib/store'
//import {shallow} from 'zustand/shallow'
//

// graphql
import { useQuery } from '@apollo/client'
import { GetShapefiles } from '../../lib/queries/shapefile.js'
import { useEffect } from 'react';
//

export default function InitShapefilesData(props) {
  const { /*loading, error, */data } = useQuery(GetShapefiles);
  const setShapefiles = useStore(state => state.setShapefiles);

  useEffect(() => {
    setShapefiles(data ? data.ako_shapefile : [])
    //console.log("shapefiles", data ? data.ako_shapefile : []);
  }, [ data, setShapefiles ]);

  return <React.Fragment/>;
}
