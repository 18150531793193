import React from 'react';
//import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// zustand
import useStore from '../../lib/store'
import {shallow} from 'zustand/shallow'

// i18n
import { useTranslation } from 'react-i18next';
//

import { BsX } from 'react-icons/bs';

import LangSelect from '../../components/LangSelect/LangSelect';

import './BurgerModal.scss';

/**
 * This is the Modal Dialog that is displayed when you click on the burger menu
 * @returns The Modal Dialog Component to include somewhere
 */

const BurgerModal = () => {
  const [ showBurgerModal, setShowBurgerModal, setSidePanelOpened, setProjectPage, setHelpPage ] = useStore(
    state => [ state.showBurgerModal, state.setShowBurgerModal, state.setSidePanelOpened, state.setProjectPage, state.setHelpPage ], shallow)

  const handleClose = () => setShowBurgerModal(false);
  const handleSearch = () => {
    setShowBurgerModal(false);
    setSidePanelOpened('left');
  }
  //const handleShow = () => setShowBurgerModal(true);
  const { t } = useTranslation();

  return (
    <Modal className="BurgerModal" fullscreen show={showBurgerModal} onHide={handleClose}>

      <Modal.Body>

        <button className="CloseButton" onClick={handleClose}><BsX/></button>

        <div className="block0">
          <div className="block1">
            <div className="block11">
              <p>
                <a href='/#' rel='noreferrer' onClick={e => { e.preventDefault(); setProjectPage('project')}}>
                {t('components.BurgerModal.link.theproject1')}
                <span style={{color: 'white'}}>ArkeOpen</span>
                {t('components.BurgerModal.link.theproject2')}
                </a>
              </p>
              <p><a href='/#' rel='noreferrer' onClick={e => { e.preventDefault(); handleSearch()}}>{t('components.BurgerModal.link.search')}</a></p>
              <p><a onClick={e => { e.preventDefault(); setProjectPage('partners')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.partners')}</a></p>
            </div>
            <div className="block12">
              <p><a onClick={e => { e.preventDefault(); setHelpPage('interface')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.inlinehelp')}</a></p>
              <div className="block121">
                <p><a onClick={e => { e.preventDefault(); setHelpPage('interface')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.interface')}</a></p>
                <p><a onClick={e => { e.preventDefault(); setHelpPage('when')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.chronology')}</a></p>
                <p><a onClick={e => { e.preventDefault(); setHelpPage('others')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.filter')}</a></p>
                <p><a onClick={e => { e.preventDefault(); setHelpPage('map')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.map')}</a></p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div><a onClick={e => { e.preventDefault(); setProjectPage('credits')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.credits')}</a></div>
        <div><a onClick={e => { e.preventDefault(); setProjectPage('cgu')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.cgu')}</a></div>
        <div><a onClick={e => { e.preventDefault(); setProjectPage('legals')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.legals')}</a></div>
        <div><a onClick={e => { e.preventDefault(); setProjectPage('contact')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.contact')}</a></div>
        <div className='Lang'><LangSelect/></div>
      </Modal.Footer>
    </Modal>
  );
}

export default BurgerModal;
