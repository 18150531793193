import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import './styles/overrides.scss';
import App from './App';
//import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from "react-router-dom";

import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink } from "@apollo/client";

import './i18n';

// Apollo Client (GRAPHQL) SETUP
// This setup is only needed once per application;
const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
    //uri: "http://home.keblo.net:5001/v1/graphql",
    fetchOptions: {
      //mode: 'no-cors',
      credentials: 'include',
    },
  }),
});

// remove the primary loading element from index.html
document.getElementById('primary-loading').remove();
document.getElementById('primary-loading-text').remove();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
