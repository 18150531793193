import React from 'react'
import PropTypes from 'prop-types'

// i18n
import { useTranslation } from 'react-i18next';

import useStore from '../../lib/store'

import { GetWhat } from '../../lib/queries/legend'
import { getCharacById } from '../../lib/characsSelection';

import Loader from '../Loader/Loader';

import { getInLang } from '../../lib/translate';

import { useQuery } from '@apollo/client';

const characToLongName = (charac, lang) => {
  let name = "";

  while (charac && charac.parent !== null) {
    name = getInLang(charac.charac_trs, lang).name + (name === "" ? "" : " / "+name);
    charac = charac.parent;
  }

  return name;
}

const LegendWhat = props => {
  const { features } = props;
  const { t, i18n } = useTranslation();
  const characs = useStore(state => state.characs);

  //console.log("characs", characs);

  const site_ids = features.map(f => f.properties.site_id);
  //console.log("site_ids", site_ids);

  const whats = useQuery(GetWhat, {
    variables: {
      site_ids: site_ids,
    }
  }) // whats = { loading, error, data }

  if (whats.loading) {
    return (
      <div className="LegendWhat">
        <Loader />
      </div>
    )
  }

  if (whats.error) {
    return (
      <div className="LegendWhat">
        {whats.error.message}
      </div>
    )
  }
  //console.log("whats.data", whats.data)

  const charac_by_ids={};
  whats.data.ako_site.forEach(
    site => site.site_ranges.forEach(
      site_range => site_range.site_range__characs.forEach(
        site_range__charac => {
          const charac_id = site_range__charac.charac_id;
          if (charac_id in charac_by_ids) charac_by_ids[charac_id].count++;
          else {
            charac_by_ids[charac_id] = {
              count: 1,
              charac: getCharacById(characs, charac_id),
            }
          }
        }
      )
    )
  )
  //console.log("charac_by_ids", charac_by_ids);

  const forEachCharac = (characs, cb) => {
    characs.forEach(charac => {
      if (charac.characs) forEachCharac(charac.characs, cb);
      cb(charac);
    })
  }

  const tree = [];
  characs.forEach(rootcharac => {
    const subcharacs = [];
    forEachCharac(rootcharac.characs, charac => {
      if (charac.id in charac_by_ids) {
        subcharacs.push(charac_by_ids[charac.id]);
      }
    })
    if (subcharacs.length > 0)
      tree.push({ rootcharac, subcharacs });
  })
  //console.log(tree);

  //const rcss = reduceRangeCharacs(_.toArray(charac_by_ids));
  //console.log("rcss", rcss);

  return (
    <div className="LegendWhat">
      <h2>{t`components.LegendWhat.title`}</h2>

      <div className="characs">
        {tree.map((root,i) => (
          <div key={i} className="RootCharac">
            <div className="RootCharacName">{getInLang(root.rootcharac.charac_trs, i18n.language).name}</div>
            <div className="SubCharacs">
              {root.subcharacs.map(subcharac => (
                <div key={subcharac.charac.id} className="SubCharac">
                  <div className="Point">&nbsp;&bull;</div>
                  <div className="CharacName">{characToLongName(subcharac.charac, i18n.language)} ({subcharac.count})</div>
                  <div className="CharacPactol">{subcharac.charac.ark_id ? <a href={subcharac.charac.ark_id} target="_blank" rel="noreferrer">PACTOLS</a> : ''}</div>
                  <div className="CharacSeparator">-</div>
                  <div className="CharacPeriodo">{subcharac.charac.aat_id ? <a href={subcharac.charac.aat_id} target="_blank" rel="noreferrer">Getty</a> : ''}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

LegendWhat.propTypes = {
  features: PropTypes.array.isRequired,
}

export default LegendWhat
