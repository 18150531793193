import { create } from "zustand"

import { compactCharacsSelection, decompactCharacsSelection } from './characsSelection';
//import { compactChronologiesSelection, decompactChronologiesSelection } from './chronologiesSelection';

import config from '../config.json'

export const UNDETERMINED_LEFT = -2147483648;
export const UNDETERMINED_RIGHT = 2147483647;

export const defaults = {
  basemap: 'PositronBlack',
  sidePanelOpened: 'none',
  rightPanel: { type: 'help', id: 'interface' },
  showWelcomeModal: true,
  showBurgerModal: false,
  lng: 10,
  lat: 45,
  zoom: 3,
  characSelection: [],
  characSelectionCompacted: [],
  chronologySelection: [],
  chronologySelectionCompacted: [],
  selectedShapefiles: [],
  chronologyStartDate: UNDETERMINED_LEFT,
  chronologyEndDate: UNDETERMINED_RIGHT,

  chronologyFindIncludeUndetermined: true,
  chronologyFindOnlyInside: false,

  knowledgeTypes: [],
  exceptional: null,
  centroid: null,
  editors: [],
  databases: [],
  authors: [],
  textual: "",
  textualOn: 'all',

  // cached graphql datas
  characs: [],
  chronologies: [],
  shapefiles: [],
  selectedChronologyId: config.chronology.default,
}

const useStore = create((set) => ({
  basemap: defaults.basemap,
  setBasemap: basemap => set({basemap}),

  sidePanelOpened: defaults.sidePanelOpened, // can be 'left', 'right', 'none'
  setSidePanelOpened: sidePanelOpened => set({sidePanelOpened}),

  rightPanel: defaults.rightPanel,
  setRightPanel: (rightPanel, openit = true) => openit ? set({rightPanel, showBurgerModal: false, sidePanelOpened: 'right'}) : set({rightPanel}),
  setProjectPage: id => set({rightPanel: { type: 'project', id }, sidePanelOpened: 'right', showBurgerModal: false}),
  setHelpPage: id => set({rightPanel: { type: 'help', id }, sidePanelOpened: 'right', showBurgerModal: false}),

  showWelcomeModal: defaults.showWelcomeModal,
  setShowWelcomeModal: showWelcomeModal => {
    if (showWelcomeModal === true)
      set({showWelcomeModal, sidePanelOpened: 'none'});
    else
      set({showWelcomeModal});
  },

  showBurgerModal: defaults.showBurgerModal,
  setShowBurgerModal: showBurgerModal => set({showBurgerModal}),

  lng: defaults.lng,
  setLng: lng => set({lng}),

  lat: defaults.lat,
  setLat: lat => set({lat}),

  zoom: defaults.zoom,
  setZoom: zoom => set({zoom}),

  mapObject: null,
  mapUpdated: 0,
  setMapObject: mapObject => set(state => { return {mapObject, mapUpdated: state.mapUpdated+1}}),
  setMapUpdated: () => set(state => { return {mapUpdated: state.mapUpdated+1}}),

  allMapDrawed: false,
  setAllMapDrawed: allMapDrawed => set({allMapDrawed}),

  /**
   * liste of all characs
   */
  characs: defaults.characs,
  setCharacs: characs => set({characs}),

  /**
   * liste of all chronologies
   */
  chronologies: defaults.chronologies,
  setChronologies: chronologies => set({chronologies}),

  /**
   * liste of all shapefiles
   */
  shapefiles: defaults.shapefiles,
  setShapefiles: shapefiles => set({shapefiles}),
}))

export const useSearchStore = create((set) => ({
  /**
   * Charac selection in the menus
   */
  characSelection: defaults.characSelection,
  setCharacSelection: characSelection => set({
    characSelection,
    characSelectionCompacted: compactCharacsSelection(characSelection)
  }),

  /**
   * Charac selection in the menus, compacted version
   */
  characSelectionCompacted: defaults.characSelectionCompacted,
  setCharacSelectionCompacted: characSelectionCompacted => set({
    characSelectionCompacted,
    characSelection: decompactCharacsSelection(characSelectionCompacted)
  }),

  /**
   * The selected chronology Id
   */
  //selectedChronologyId: defaults.selectedChronologyId,
  selectedChronologyId: defaults.selectedChronologyId,
  setSelectedChronologyId: selectedChronologyId => set({
    selectedChronologyId,
  }),

  /**
   * chronologyStartDate: a year number
   */
  chronologyStartDate: defaults.chronologyStartDate,
  setChronologyStartDate: chronologyStartDate => set({chronologyStartDate}),

  /**
   * chronologyEndDate: a year number
   */
  chronologyEndDate: defaults.chronologyEndDate,
  setChronologyEndDate: chronologyEndDate => set({chronologyEndDate}),

  /**
   * setChronologyStartEndDate: set start and end date (the two previous functions in one)
   * @param {number} chronologyStartDate
   * @param {number} chronologyEndDate
   */
  setChronologyStartEndDate: (chronologyStartDate, chronologyEndDate) => set({chronologyStartDate, chronologyEndDate}),

  /**
   * chronologyFindIncludeUndetermined: boolean
   */
  chronologyFindIncludeUndetermined: defaults.chronologyFindIncludeUndetermined,
  setChronologyFindIncludeUndetermined: chronologyFindIncludeUndetermined => set({chronologyFindIncludeUndetermined}),

  /**
   * chronologyFindOnlyInside: boolean
   */
  chronologyFindOnlyInside: defaults.chronologyFindOnlyInside,
  setChronologyFindOnlyInside: chronologyFindOnlyInside => set({chronologyFindOnlyInside}),

  /**
   * The selected shapefiles (array of id)
   */
  selectedShapefiles: defaults.selectedShapefiles,
  setSelectedShapefiles: selectedShapefiles => set({selectedShapefiles}),

  /**
   * knowledgeTypes: array of words ('not-documented', 'literature', 'dig', ...)
   */
  knowledgeTypes: defaults.knowledgeTypes,
  setKnowledgeTypes: knowledgeTypes => set({knowledgeTypes}),
  updateKnowledgeTypes: (sel, knowledgeType) => set(state => {
    return {
      knowledgeTypes: sel ? [ ...state.knowledgeTypes, knowledgeType ] : state.knowledgeTypes.filter(v => v !== knowledgeType)
    }
  }),

  /**
   * centroid: boolean or null
   */
  centroid: defaults.centroid,
  setCentroid: centroid => set({centroid}),

  /**
   * exceptional: boolean or null
   */
  exceptional: defaults.exceptional,
  setExceptional: exceptional => set({exceptional}),

  /**
   * editors: array of strings ('University of ...')
   */
   editors: defaults.editors,
   setEditors: editors => set({editors}),
   updateEditors: (sel, editor) => set(state => {
     return {
      editors: sel ? [ ...state.editors, editor ] : state.editors.filter(v => v !== editor)
     }
   }),

  /**
   * authors: array of ids
   */
   authors: defaults.authors,
   setAuthors: authors => set({authors}),
   updateAuthors: (sel, author) => set(state => {
     return {
      authors: sel ? [ ...state.authors, author ] : state.authors.filter(v => v !== author)
     }
   }),

  /**
   * databases: array of ids
   */
   databases: defaults.databases,
   setDatabases: databases => set({databases}),
   updateDatabases: (sel, database) => set(state => {
     return {
      databases: sel ? [ ...state.databases, database ] : state.databases.filter(v => v !== database)
     }
   }),

  /**
   * textual: search string
   */
  textual: defaults.textual,
  setTextual: textual => set({textual}),

  /**
   * textual: enum of ('all', 'dataset-name', 'resource-name', 'comments', 'bibliography')
   */
  textualOn: defaults.textualOn,
  setTextualOn: textualOn => set({textualOn}),


  clearSearch: () => set({
    characSelection: defaults.characSelection,
    characSelectionCompacted: defaults.characSelectionCompacted,
    selectedChronologyId: defaults.selectedChronologyId,
    chronologyStartDate: defaults.chronologyStartDate,
    chronologyEndDate: defaults.chronologyEndDate,
    chronologyFindIncludeUndetermined: defaults.chronologyFindIncludeUndetermined,
    chronologyFindOnlyInside: defaults.chronologyFindOnlyInside,
    selectedShapefiles: defaults.selectedShapefiles,
    knowledgeTypes: defaults.knowledgeTypes,
    centroid: defaults.centroid,
    exceptional: defaults.exceptional,
    editors: defaults.editors,
    authors: defaults.authors,
    databases: defaults.databases,
    textual: defaults.textual,
    textualOn: defaults.textualOn,
  }),

  copyFromValidated: () => {
    const searchState = useValidatedSearchStore.getState();
    set({
      characSelection: searchState.characSelection,
      characSelectionCompacted: searchState.characSelectionCompacted,
      selectedChronologyId: searchState.selectedChronologyId,
      chronologyStartDate: searchState.chronologyStartDate,
      chronologyEndDate: searchState.chronologyEndDate,
      chronologyFindIncludeUndetermined: searchState.chronologyFindIncludeUndetermined,
      chronologyFindOnlyInside: searchState.chronologyFindOnlyInside,
      selectedShapefiles: searchState.selectedShapefiles,
      knowledgeTypes: searchState.knowledgeTypes,
      centroid: searchState.centroid,
      exceptional: searchState.exceptional,
      editors: searchState.editors,
      authors: searchState.authors,
      databases: searchState.databases,
      textual: searchState.textual,
      textualOn: searchState.textualOn,
    });
  },

}))

export const useValidatedSearchStore = create((set) => ({
  /**
   * Charac selection in the menus
   */
  characSelection: defaults.characSelection,
  setCharacSelection: characSelection => set({
    characSelection,
    characSelectionCompacted: compactCharacsSelection(characSelection)
  }),

  /**
   * Charac selection in the menus, compacted version
   */
  characSelectionCompacted: defaults.characSelectionCompacted,
  setCharacSelectionCompacted: characSelectionCompacted => set({
    characSelectionCompacted,
    characSelection: decompactCharacsSelection(characSelectionCompacted)
  }),

  /**
   * The selected chronology Id
   */
  //selectedChronologyId: defaults.selectedChronologyId,
  selectedChronologyId: defaults.selectedChronologyId,
  setSelectedChronologyId: selectedChronologyId => set({
    selectedChronologyId,
  }),

  /**
   * chronologyStartDate: a year number
   */
  chronologyStartDate: defaults.chronologyStartDate,
  setChronologyStartDate: chronologyStartDate => set({chronologyStartDate}),

  /**
   * chronologyEndDate: a year number
   */
  chronologyEndDate: defaults.chronologyEndDate,
  setChronologyEndDate: chronologyEndDate => set({chronologyEndDate}),

  /**
   * chronologyFindIncludeUndetermined: boolean
   */
  chronologyFindIncludeUndetermined: defaults.chronologyFindIncludeUndetermined,
  setChronologyFindIncludeUndetermined: chronologyFindIncludeUndetermined => set({chronologyFindIncludeUndetermined}),

  /**
   * chronologyFindOnlyInside: boolean
   */
  chronologyFindOnlyInside: defaults.chronologyFindOnlyInside,
  setChronologyFindOnlyInside: chronologyFindOnlyInside => set({chronologyFindOnlyInside}),

   /**
   * The selected shapefiles (array of id)
   */
  selectedShapefiles: defaults.selectedShapefiles,
  setSelectedShapefiles: selectedShapefiles => set({selectedShapefiles}),

  /**
   * knowledgeTypes: array of words ('not-documented', 'literature', 'dig', ...)
   */
  knowledgeTypes: defaults.knowledgeTypes,
  setKnowledgeTypes: knowledgeTypes => set({knowledgeTypes}),
  updateKnowledgeTypes: (sel, knowledgeType) => set(state => {
    return {
      knowledgeTypes: sel ? [ ...state.knowledgeTypes, knowledgeType ] : state.knowledgeTypes.filter(v => v !== knowledgeType)
    }
  }),

  /**
  * centroid: boolean or null
  */
  centroid: defaults.centroid,
  setCentroid: centroid => set({centroid}),

  /**
  * exceptional: boolean or null
  */
  exceptional: defaults.exceptional,
  setExceptional: exceptional => set({exceptional}),

  /**
  * editors: array of strings ('University of ...')
  */
  editors: defaults.editors,
  setEditors: editors => set({editors}),
  updateEditors: (sel, editor) => set(state => {
    return {
      editors: sel ? [ ...state.editors, editor ] : state.editors.filter(v => v !== editor)
    }
  }),

  /**
  * authors: array of ids
  */
  authors: defaults.authors,
  setAuthors: authors => set({authors}),
  updateAuthors: (sel, author) => set(state => {
    return {
      authors: sel ? [ ...state.authors, author ] : state.authors.filter(v => v !== author)
    }
  }),

  /**
  * databases: array of ids
  */
  databases: defaults.databases,
  setDatabases: databases => set({databases}),
  updateDatabases: (sel, database) => set(state => {
    return {
      databases: sel ? [ ...state.databases, database ] : state.databases.filter(v => v !== database)
    }
  }),

  /**
  * textual: search string
  */
  textual: defaults.textual,
  setTextual: textual => set({textual}),

  /**
  * textual: enum of ('all', 'dataset-name', 'resource-name', 'comments', 'bibliography')
  */
  textualOn: defaults.textualOn,
  setTextualOn: textualOn => set({textualOn}),

  geojsonResult: null,
  setGeojsonResult: (geojsonResult) => set({geojsonResult}),

  validateSearch: () => {
    const searchState = useSearchStore.getState();
    set({
      characSelection: searchState.characSelection,
      characSelectionCompacted: searchState.characSelectionCompacted,
      selectedChronologyId: searchState.selectedChronologyId,
      chronologyStartDate: searchState.chronologyStartDate,
      chronologyEndDate: searchState.chronologyEndDate,
      chronologyFindIncludeUndetermined: searchState.chronologyFindIncludeUndetermined,
      chronologyFindOnlyInside: searchState.chronologyFindOnlyInside,
      selectedShapefiles: searchState.selectedShapefiles,
      knowledgeTypes: searchState.knowledgeTypes,
      centroid: searchState.centroid,
      exceptional: searchState.exceptional,
      editors: searchState.editors,
      authors: searchState.authors,
      databases: searchState.databases,
      textual: searchState.textual,
      textualOn: searchState.textualOn,
      //geojsonResult: null,
    });
  },

  clearSearch: () => set({
    characSelection: defaults.characSelection,
    characSelectionCompacted: defaults.characSelectionCompacted,
    selectedChronologyId: defaults.selectedChronologyId,
    chronologyStartDate: defaults.chronologyStartDate,
    chronologyEndDate: defaults.chronologyEndDate,
    chronologyFindIncludeUndetermined: defaults.chronologyFindIncludeUndetermined,
    chronologyFindOnlyInside: defaults.chronologyFindOnlyInside,
    selectedShapefiles: defaults.selectedShapefiles,
    knowledgeTypes: defaults.knowledgeTypes,
    centroid: defaults.centroid,
    exceptional: defaults.exceptional,
    editors: defaults.editors,
    authors: defaults.authors,
    databases: defaults.databases,
    textual: defaults.textual,
    textualOn: defaults.textualOn,
    geojsonResult: null,
  }),
}))


export default useStore;
