import React, { useEffect, useState } from 'react'
//import PropTypes from 'prop-types'

// react bootstrap
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

// i18n
import { useTranslation } from 'react-i18next';

import LegendWhen from './LegendWhen';
import LegendWhat from './LegendWhat';
import LegendWhere from './LegendWhere';
import LegendOther from './LegendOther';
import LegendCitations from './LegendCitations';
import ShareButton from '../ShareButton/ShareButton';

import useStore from '../../lib/store'
import {shallow} from 'zustand/shallow'

import './Legend.scss'
import CloseButton from '../CloseButton/CloseButton';

const Legend = () => {
  const { t, i18n } = useTranslation();
  const [ features, setFeatures ] = useState([]);
  const [ sidePanelOpened, map, mapUpdated, allMapDrawed, rightPanel, setRightPanel ] = useStore(state => [
    state.sidePanelOpened, state.mapObject, state.mapUpdated, state.allMapDrawed, state.rightPanel, state.setRightPanel
  ], shallow)

  useEffect(() => {
    let allfeatures = [];
    if (map && allMapDrawed && sidePanelOpened === "right") {
      const features_circle = map.queryRenderedFeatures(undefined, { layers: ['arkeosite_circle']});
      //console.log("features_circle: ", features_circle);
      allfeatures.push(...features_circle)

      const clusterSource = map.getSource('arkeosites');

      const pGetClusterLeaves = (clusterSource, cluster_id, max, offset) => {
        return new Promise((resolve, reject) => {
          clusterSource.getClusterLeaves(cluster_id, max, offset, (err, res) => {
            if (err) reject(err);
            else resolve(res);
          })
        })
      }

      const features_cluster = map.queryRenderedFeatures(undefined, { layers: ['arkeosite_cluster']});
      Promise.all(features_cluster
        .map(clusterFeature => pGetClusterLeaves(clusterSource, clusterFeature.id, 1000000, 0)
          .then(res => allfeatures.push(...res))
        ))
        .then(res => {
          setFeatures(allfeatures);
        }, err => {
          console.log("error: ", err);
        })
    }
  }, [map, mapUpdated, allMapDrawed, sidePanelOpened]);

  return (
    <div className="Legend">
      <CloseButton />
      <div className="LegendHeader">
        <div className="LegendHeaderTitle">{t('components.Legend.title')}</div>
        <div className="LegendResultSummary">
          <div>{t('components.Legend.ResultsSummary.on')} {new Date().toLocaleDateString(i18n.language)}</div>
          <div>{features.length} {t('components.Legend.ResultsSummary.count')}</div>
        </div>
        <div className="LegendHeaderButtons">
          <ShareButton />
        </div>
      </div>
      <Tabs
        activeKey={rightPanel.id}
        onSelect={k => setRightPanel({...rightPanel, id: k})}
        className="mb-3"
        unmountOnExit={true}
        fill
      >
        <Tab eventKey="when" title={<div className='PedroTab'>{t('components.Legend.when.title')}</div>}>
          <LegendWhen features={features}/>
        </Tab>
        <Tab eventKey="what" title={<div className='PedroTab'>{t('components.Legend.what.title')}</div>}>
          <LegendWhat features={features}/>
        </Tab>
        <Tab eventKey="where" title={<div className='PedroTab'>{t('components.Legend.where.title')}</div>}>
          <LegendWhere />
        </Tab>
        <Tab eventKey="other" title={<div className='PedroTab'>{t('components.Legend.other.title')}</div>}>
          <LegendOther features={features}/>
        </Tab>
        <Tab eventKey="citations" title={<div className='PedroTab'>{t('components.Legend.citations.title')}</div>}>
          <LegendCitations features={features}/>
        </Tab>
      </Tabs>
    </div>
  )
}

Legend.propTypes = {}

export default Legend
