import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { GetFullSiteQuery } from '../../lib/queries/sites';
import Loader from '../Loader/Loader';
import ShareButton from '../ShareButton/ShareButton';
import PicturesButton from '../PicturesButton/PicturesButton';
import { useQuery } from '@apollo/client';
import Moment from 'react-moment';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';

import Markdown from '../Markdown/Markdown';

import { MdError } from "react-icons/md";

// i18n
import { useTranslation } from 'react-i18next';
import { getInLang } from '../../lib/translate';
//

import useStore, { useValidatedSearchStore } from '../../lib/store'

import { yearToHuman } from '../../lib/year';

import { getChronologyByDatel1, getChronologyByDatel4 } from '../../lib/chronologiesSelection';

import config from '../../config.json';
import { UNDETERMINED_LEFT } from '../../lib/year';
import MiniMap from '../MiniMap/MiniMap';

import _ from 'underscore';

import './SiteComponent.scss';
import { useEffect } from 'react';
import CloseButton from '../CloseButton/CloseButton';

const SiteComponent = props => {
  const { t/*, i18n*/ } = useTranslation();
  const { id } = props;
  const setRightPanel = useStore(state => state.setRightPanel);
  const chronologies = useStore(state => state.chronologies);
  const selectedChronologyId = useValidatedSearchStore(state => state.selectedChronologyId);
  const [geojson, setGeojson] = useState(undefined);

  const { loading, error, data } = useQuery(GetFullSiteQuery, {
    variables: {
      id: id,
    },
  })

  const site = data && data.ako_site && data.ako_site.length > 0 ? data.ako_site[0] : undefined;

  useEffect(() => {
    if (!site) return;

    const selectedChronology = chronologies.find(c => c.id === selectedChronologyId);
    const p1 = getChronologyByDatel1(selectedChronology.chronologies, site.end_date1, site.end_date2);
    const p2 = getChronologyByDatel4(selectedChronology.chronologies, site.end_date1, site.end_date2);
    const _geojson = {
      "type": "FeatureCollection",
      "features": [{
        "type": "Feature",
        "properties": {
          "name": site.name,
          //"mag": site.end_date1,
          //"dates": [ site.start_date1, site.start_date2, site.end_date1, site.end_date2],
          "start_date1": site.start_date1,
          "start_date2": site.start_date2,
          "end_date1": site.end_date1,
          "end_date2": site.end_date2,
          "periodeid": p1 ? p1.id : (site.end_date1 === UNDETERMINED_LEFT ? config.chronology.undetermined.id : config.chronology.outside.id),
          "periodeid2": p2 ? p2.id : (site.end_date1 === UNDETERMINED_LEFT ? config.chronology.undetermined.id : config.chronology.outside.id),
          "color": p2 ? '#'+p2.color : (site.end_date1 === UNDETERMINED_LEFT ? config.chronology.undetermined.color : config.chronology.outside.color),
          //"exceptional": site.site_ranges_aggregate.aggregate.count > 0 ? 'yes' : 'no',
          "exceptional": site.site_ranges.map(sr => sr.site_range__characs.map(src => src.exceptional)).flat().includes(true) ? "yes" : "no",
          "site_id": site.id,
        },
        "geometry": {
          "type": "Point",
          "coordinates": site.geom.coordinates
        }
      }]
    };
    setGeojson(_geojson);
  }, [setGeojson, selectedChronologyId, chronologies, data])

  if (loading) {
    return <Loader/>
  }

  if (error) {
    return (
      <div className="SiteComponent">
        <div className="error">
        <MdError size={48}/> {error.message}
        </div>
      </div>
    );
  }

  if (!data.ako_site || data.ako_site.length === 0) {
    return (
      <div className="SiteComponent">
        <div className="error">
        <MdError size={48}/> site not found
        </div>
      </div>
    );
  }

  if (!geojson) {
    return (
      <div className="SiteComponent">
        <div className="error">
        <MdError size={48}/> No coords ?
        </div>
      </div>
    );
  }

  //console.log("site: ", site);

  return (
    <div className="SiteComponent">

      <MiniMap minimaptype="site" geojson={geojson}/>

      <CloseButton />

      <div className="Site">
        <div className="SiteHeader">
          <div className="SiteTitle">{site.name}</div>
          <div className="SiteId">Id {site.id}</div>
          <div className="SitePictures">
            <PicturesButton site={site} v={1} />
          </div>
          <div className="SiteShare">
            <ShareButton />
          </div>
        </div>

        <SiteCoverages site={site}/>

        <hr />

        <div className="SiteDatabaseInfos">
          <div className="col1">
            <div>
              <strong>{t("components.SiteComponent.Database.Authors.title")}:&nbsp;</strong><DatabaseAuthors database={site.database}/>
            </div>
            <div>
              <strong>{t("components.SiteComponent.Database.Dataset.title")}:&nbsp;</strong>
              <a
                href='/#'
                rel="noreferrer"
                onClick={
                  e => {
                    e.preventDefault();
                    setRightPanel({type: 'db', id: site.database.id})
                  }
                }
              >{site.database.name}</a>
            </div>
          </div>
          <div className="col2">
            <div>
              <strong>{t("components.SiteComponent.Database.Dataset.date_created")}:&nbsp;</strong><Moment format="DD/MM/YYYY hh:mm">{site.database.declared_creation_date}</Moment>
            </div>
            <div>
              <strong>{t("components.SiteComponent.Database.Dataset.date_updated")}:&nbsp;</strong><Moment format="DD/MM/YYYY hh:mm">{site.database.updated_at}</Moment>
            </div>
          </div>
        </div>

        <hr />

        <SiteRanges site={site}/>

      </div>
    </div>
  )
}

const SiteCoverage1 = props => {
  const { site } = props;
  const { t, i18n } = useTranslation();

  const selectedChronologyId = useValidatedSearchStore(state => state.selectedChronologyId);
  const chronologies = useStore(state => state.chronologies);
  const selectedChronology = chronologies.find(c => c.id === selectedChronologyId);

  const chrono_start = getChronologyByDatel4(selectedChronology.chronologies, site.start_date1, site.start_date2);
  const chrono_end = getChronologyByDatel4(selectedChronology.chronologies, site.end_date1, site.end_date2);

  const color_start = chrono_start ? '#'+chrono_start.color : '';
  const color_end = chrono_end ? '#'+chrono_end.color : '';

  return (
    <div className="SiteCoverage1">
      <div><strong>{t("components.SiteComponent.SiteCoverage1.title")}</strong></div>
      <table>
        <tbody>
        <tr>
            <th>{t("components.SiteComponent.SiteCoverage1.start")}</th>
            <td>{yearToHuman(site.start_date1)} =&gt; {yearToHuman(site.start_date2)}</td>
            <td>{chrono_start ? getInLang(chrono_start.chronology_trs, i18n.language).name : ''}</td>
            <td>{chrono_start ? <div className="colorblock" style={{backgroundColor: color_start}}/> : '' }</td>
          </tr>
          <tr>
            <th>{t("components.SiteComponent.SiteCoverage1.end")}</th>
            <td>{yearToHuman(site.end_date1)} =&gt; {yearToHuman(site.end_date2)}</td>
            <td>{chrono_end ? getInLang(chrono_end.chronology_trs, i18n.language).name : ''}</td>
            <td>{chrono_end ? <div className="colorblock" style={{backgroundColor: color_end}}/> : '' }</td>
          </tr>
          <tr>
            <th>{t("components.SiteComponent.SiteCoverage1.occupation")}</th>
            <td colSpan={3}>
              {site.occupation === 'not_documented' ? t("arkeo.occupation.not_documented") : ''}
              {site.occupation === 'single' ? t("arkeo.occupation.single") : ''}
              {site.occupation === 'continuous' ? t("arkeo.occupation.continuous") : ''}
              {site.occupation === 'multiple' ? t("arkeo.occupation.multiple") : ''}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

const SiteCoverage2 = props => {
  const { site } = props;
  const { t/*, i18n*/ } = useTranslation();

  return (
    <div className="SiteCoverage2">
      <div><strong>{t("components.SiteComponent.SiteCoverage2.title")}</strong></div>
      <div>
        <strong>{t("components.SiteComponent.SiteCoverage2.latitude.title")}:&nbsp;</strong>{site.geom.coordinates[0]}
        &nbsp;<strong>{t("components.SiteComponent.SiteCoverage2.longitude.title")}:&nbsp;</strong>{site.geom.coordinates[1]}
        &nbsp;<strong>{t("components.SiteComponent.SiteCoverage2.altitude.title")}:&nbsp;</strong>{site.altitude === -999999 ? '-' : site.altitude + " mts"}
      </div>
      <div>
        <strong>{t("components.SiteComponent.SiteCoverage2.geoname.title")}:&nbsp;</strong><a target='_blank' rel="noreferrer" href={`https://www.geonames.org/${site.city_geonameid}`}>{site.city_geonameid}</a>
        &nbsp;<strong>{t("components.SiteComponent.SiteCoverage2.coords.title")}:&nbsp;</strong>
          {site.centroid ? t("components.SiteComponent.SiteCoverage2.coords.centroid") : t("components.SiteComponent.SiteCoverage2.coords.notcentroid")}
      </div>
      <div>
        <strong>{t("components.SiteComponent.SiteCoverage2.city.title")}:&nbsp;</strong>{site.city_name}
      </div>
    </div>
  );
}

const SiteCoverages = props => {
  const { site } = props;

  return (
    <div className="SiteCoverages">
      <SiteCoverage1 site={site}/>
      <SiteCoverage2 site={site}/>
    </div>
  )
}

const SiteRanges = props => {
  const { site } = props;
  const [ key, setKey ] = useState(site && site.site_ranges && site.site_ranges.length > 0 ? 'ek'+site.site_ranges[0].id : 'none')

  useEffect(() => {
    setKey(site && site.site_ranges && site.site_ranges.length > 0 ? 'ek'+site.site_ranges[0].id : 'none');
  }, [site]);

  return (
    <Tabs
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      {site.site_ranges.map(range => (
        <Tab key={range.id} eventKey={"ek"+range.id} title={
          <div className='PedroTab'>
            OCCUPATION {yearToHuman(range.start_date1)} =&gt; {yearToHuman(range.end_date2)}
          </div>
        }>
          <Characs range_characs={range.site_range__characs}/>
        </Tab>
      ))}
    </Tabs>
  )
}

const reversecharac = charac => {
  const acumulator = [];
  for (let c=charac; c && c.id>0; c=c.charac) acumulator.push({id: c.id, charac_trs: c.charac_trs, parent_id: c.parent_id, ark_id: c.ark_id});
  acumulator.reverse();
  return acumulator;
}

const reorderCharacs = rcs => {
  return rcs.map(rc => { return {
    id: rc.id,
    exceptional: rc.exceptional,
    knowledge_type: rc.knowledge_type,
    web_images: rc.web_images,
    charac: reversecharac(rc.charac),
    site_range__charac_trs: rc.site_range__charac_trs,
  }})
}

const reduceRangeCharacs = rcs => {
  rcs = reorderCharacs(rcs);
  const rcss = [];
  rcs.forEach(rc => {
    if (rcss.length === 0) {
      rcss.push([rc]);
    } else {
      if (rcss[rcss.length - 1][0].charac[0].id === rc.charac[0].id) {
        rcss[rcss.length - 1].push(rc);
      } else {
        rcss.push([rc]);
      }
    }
  })
  return rcss;
}

const subCharac = (characA, lang) => {
  let namepath = '';
  for (let i=1; i<characA.length; i++) {
    namepath += (i > 1 ? ' / ' : '') + getInLang(characA[i].charac_trs, lang).name;
  }
  return namepath;
}

const Characs = props => {
  const { t, i18n } = useTranslation();
  const { range_characs } = props;
  const rcss = reduceRangeCharacs(range_characs);
  const [ showAnnexes, setShowAnnexes ] = useState(true);

  const showhideannexes = (e) => {
    setShowAnnexes(!showAnnexes);
    e.stopPropagation();
    return false;
  }

  const RootCharac = (rcs, rcs_i) => (
    <Accordion.Item eventKey={rcs_i} key={rcs_i}>
      <Accordion.Header>{getInLang(rcs[0].charac[0].charac_trs, i18n.language).name}<a className="openCharac" rel="noreferer" href='/#' onClick={e => {e.preventDefault(); showhideannexes(e)}}>{t("components.SiteComponent.CharacAccordion.header")}</a></Accordion.Header>
      <Accordion.Body>
        <ul>
          {rcs.map(rc => (
            <li key={rc.id}>
              <strong>
                {subCharac(rc.charac, i18n.language)}
                {rc.exceptional ? ' - '+t('components.SiteComponent.RangeCharac.exceptional') : ''}
              </strong>
              <div>{t('components.SiteComponent.RangeCharac.knowledge_type.label')}: <strong>{t(`arkeo.knowledge_type.${rc.knowledge_type}`)}</strong></div>
              {showAnnexes ? (
                <React.Fragment>
                  <div><strong>{t('components.SiteComponent.RangeCharac.comment.label')}: </strong><div className="RangeCharacMD"><Markdown>{getInLang(rc.site_range__charac_trs, i18n.language).comment}</Markdown></div></div>
                  <div><strong>{t('components.SiteComponent.RangeCharac.bibliography.label')}: </strong><div className="RangeCharacMD"><Markdown>{getInLang(rc.site_range__charac_trs, i18n.language).bibliography}</Markdown></div></div>
                </React.Fragment>
                ) : ''}
            </li>
          ))}
        </ul>
      </Accordion.Body>
    </Accordion.Item>
  )

  return (
    <div className="range_characs">
      <Accordion alwaysOpen defaultActiveKey={_.range(rcss.length)}>
        {rcss.map((rcs, rcs_i) => RootCharac(rcs, rcs_i))}
      </Accordion>
    </div>
  );
  /*
  const { range_characs } = props;

  const charac_tree = [];
  range_characs.forEach(range_charac => {

  })

  return (
    <div className="range_characs">
      {range_characs.map(range_charac =>
        <div className="range_charac">

        </div>
      )}
    </div>
  )
  */
}

const DatabaseAuthors = props => {
  const { database } = props;
  const authorsstr = database.database__authors.map(author => author.user.firstname + " " + author.user.lastname).join(', ');
  return (
    <React.Fragment>{authorsstr}</React.Fragment>
  )
}

SiteComponent.propTypes = {
  id: PropTypes.number.isRequired,
}

export default SiteComponent
