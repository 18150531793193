
const langprefsorder = {
  'fr': [ 'fr', 'en', 'es', 'de'],
  'en': [ 'en', 'fr', 'es', 'de'],
  'de': [ 'de', 'en', 'fr', 'es'],
  'es': [ 'es', 'en', 'fr', 'de'],
}

/**
 * getInLangWKey
 * @param {map of object containing strings values} trs
 * @param {string} wantedlang is the primary wanted language
 * @returns {object of strings} the object containg all translations of any key
 */

/**
 * getInLang
 * @param {map of object containing strings values} trs
 * @param {string} wantedlang is the primary wanted language
 * @returns {object of strings} the object containg all translations of any key
 */
export function getInLang(trs, wantedlang='fr') {
  for (const lang of langprefsorder[wantedlang]) {
    for (const tr of trs) {
      if (tr.lang_isocode === lang)
        return tr;
    }
  }
  if (trs.length > 0) return trs[0];
  return "-"
}

/**
 * getInLangWKey
 * @param {map of object containing strings values} trs
 * @param {*} key : the wanted key from the translated object
 * @param {string} wantedlang is the primary wanted language
 * @param {*} options
 * @returns {strings or undefined} the translated string
 */
export function getInLangWKey(trs, key, wantedlang='fr', options={}) {
  const defaults = {
    emptyIsUndefined: false,
  };
  const actual = Object.assign({}, defaults, options);

  for (const lang of langprefsorder[wantedlang]) {
    for (const tr of trs) {
      if (tr.lang_isocode === lang) {
        if (actual.emptyIsUndefined) {
          if (key in tr && typeof tr[key] === "string" && tr[key].trim() !== "") {
            return tr[key];
          }
        } else {
          return tr[key];
        }
      }
    }
  }
  return ""
}
