import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Modal from 'react-bootstrap/Modal';
import Popover from 'react-bootstrap/Popover';
import _ from 'underscore';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import getManifest from '../../lib/nakala2iiif/nakala2iiif';
//import { IoBookmarksSharp } from "react-icons/io5";
import { MdArrowDropDownCircle } from "react-icons/md";
import { MdFullscreen } from "react-icons/md";

// i18n
import { useTranslation } from 'react-i18next';

import './PicturesButton.scss'

export const PicturesButton = props => {
  const { site, v } = props;
  const { t/*, i18n*/ } = useTranslation();
  const [ show, setShow ] = useState(false);
  const [ urls, setUrls ] = useState([]);
  const fullScreeenHandle = useFullScreenHandle();
  const [ clickFullScreen, setClickFullScreen ] = useState(0);
  const [ clickResourceChooser, setClickResourceChooser ] = useState(0);

  const _setShow = (show) => {
    setClickFullScreen(0);
    setClickResourceChooser(0);
    setShow(show);
  }

  React.useEffect(() => {
    console.log("clickFullScreen: ", clickFullScreen, fullScreeenHandle.active);
    if (clickFullScreen == 0) {
      fullScreeenHandle.exit();
      return;
    };
    fullScreeenHandle.active ? fullScreeenHandle.exit() : fullScreeenHandle.enter();
  }, [clickFullScreen]);

  useEffect(() => {
    let _urls = [];
    site.site_ranges.forEach(range => {
      range.site_range__characs.forEach(rc => {
        rc.web_images.trim().split(/[, ]+/).forEach(url => url !== '' ? _urls.push(url) : '');
      })
    });
    //_urls.push("https://iiif.home.keblo.net/manifest/10.34847/nkl.dad5x46w"); // martinique jpeg
    //_urls.push("https://iiif.home.keblo.net/manifest/10.34847/nkl.d86c3i95"); // png
    //_urls.push("https://iiif.home.keblo.net/manifest/10.34847/nkl.0c675041"); // pdf 499 pages
    //_urls.push("https://nakala.fr/10.34847/nkl.0c675041"); // pdf 499 pages
    //_urls.push("https://nakala.fr/10.34847/nkl.d6bem54v"); // jpeg2000 plusieurs images
    //_urls.push("https://nakala.fr/10.34847/nkl.397avfm8"); // SVG
    //_urls.push("https://nakala.fr/10.34847/nkl.95aac784"); // TIFF
    //_urls.push("https://nakala.fr/10.34847/nkl.cceeu6t6"); // jpeg + gif
    //_urls.push("https://nakala.fr/10.34847/nkl.a45dz9z6"); // BMP
    //_urls.push("https://nakala.fr/10.34847/nkl.5ad252tu"); // mp4 (video)
    _urls = _.uniq(_urls);
    //console.log("settings urls : ", _urls);
    setUrls(_urls);
  }, [site, setUrls]);

  //console.log("urls: ", urls);

  return (
    <div className={"v"+v}>
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement='top'
        overlay={
          <Popover id={`popover-positioned-top`}>
            <Popover.Body>
              {urls.length > 0 ? t('components.PicturesButton.pictures_label') : t('components.PicturesButton.nopictures_label')}
            </Popover.Body>
          </Popover>
        }
      >
        <button className="PicturesButton" onClick={e => _setShow(true)} disabled={urls.length == 0}>
          <img alt="pictures" src='/icons/pictures.png'/>
        </button>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={() => _setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <FullScreen handle={fullScreeenHandle} className="mirador-iframe-fullscreen">
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">

            { v == 1 && (
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement='top'
                overlay={
                  <Popover id={`popover-positioned-top`}>
                    <Popover.Body>
                      {t('components.pictures_browser.button_choose_resource')}
                    </Popover.Body>
                  </Popover>
                }
              >
                <button  className="ChooseResource" onClick={e => {setClickResourceChooser(clickResourceChooser+1)}} disabled={0}>
                  <MdArrowDropDownCircle size="32px" />
                </button>
              </OverlayTrigger>
            )}

            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement='top'
              overlay={
                <Popover id={`popover-positioned-top`}>
                  <Popover.Body>
                    {t('components.pictures_browser.button_fullscreen')}
                  </Popover.Body>
                </Popover>
              }
            >
              <button className="FullScreen" onClick={e => {setClickFullScreen(clickFullScreen+1)}} disabled={0}>
                <MdFullscreen size="32px" />
              </button>
            </OverlayTrigger>


            Illustrations du site {site.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MyFrame
            site={site}
            urls={urls}
            v={v}
            clickResourceChooser={clickResourceChooser}
            clickFullScreen={clickFullScreen}
          />
        </Modal.Body>
      </FullScreen>
      </Modal>
    </div>
  )
}

const MyFrame = props => {
  const { t, i18n } = useTranslation();
  const { site, urls, v, clickResourceChooser } = props;

  let myRef = React.createRef();

  React.useEffect(() => {
    if (clickResourceChooser == 0) return;
    myRef.current.contentWindow.postMessage({arkeopenMessage: "clickResourceChooser"}, "*");
  }, [clickResourceChooser]);

  React.useEffect(() => {
    //console.log(myRef.current);
    if (myRef.current && site) {

      myRef.current.contentWindow.arkeo_language = i18n.language;

      // convert urls to iiif resources using getManifest async method
      Promise.all(urls.map(async (url) => {
        if (url.startsWith('https://doi.org/') || url.startsWith('https://nakala.fr/')) {
          const murl=url.match(/^https:\/\/(doi\.org|nakala\.fr)\/([0-9]+\.[0-9]+)\/(nkl\.[0-9a-z]+)$/);
          //console.log(url, murl);

          let manifest = await getManifest(murl[2], murl[3]);

          if (0) {
            return manifest;
          } else {
            // convert manifest text content to a data url
            console.log("manifest nakala : ", manifest);
            const blob = new Blob([JSON.stringify(manifest, null, 2)], {type : 'application/json'});
            return URL.createObjectURL(blob);
          }
        } else {
          return url;
        }
      })).then(urls => {
        console.log("urls: ", urls);
        myRef.current.contentWindow.iiifresources = urls;
        myRef.current.contentWindow.postMessage({arkeopenMessage: "ready"}, "*");
      }, error => {
        console.error("error: ", error);
      });

    }
  }, [myRef.current, site]);

  return (
      <iframe ref={myRef} width="100%" height="100%"  src={'/mirador/' + (v > 1 ? `index-${v}.html` : 'index.html')} />
  )
}

PicturesButton.propTypes = {
  site: PropTypes.object.isRequired,
  v: PropTypes.number.isRequired
}

export default PicturesButton;
