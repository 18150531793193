import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

//const linkify = require('markdown-linkify');
//const linkify = require('linkify-it');


const Markdown = props => {
  const { children } = props;

  return (
    <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{children}</ReactMarkdown>
  )
}

Markdown.propTypes = {
  children: PropTypes.string.isRequired,
}

export default Markdown
