import React, { useEffect } from 'react'
import './Main.css'
import MainMap from '../MainMap/MainMap'
import Chronoview from '../Chronoview/Chronoview'
import SidePanel from '../SidePanel/SidePanel'
import MapSideSearch from '../MapSideSearch/MapSideSearch'
import SiteComponent from '../SiteComponent/SiteComponent'
//import PagesHelp from '../PagesHelp/PagesHelp'
import PagesProject from '../PagesProject/PagesProject'
import Legend from '../Legend/Legend'
import PanelChronology from '../PanelChronology/PanelChronology'
import PanelShapefile from '../PanelShapefile/PanelShapefile'
import PanelDataset from '../PanelDataset/PanelDataset'
import Help from '../TabbedMDComponent/Help'
import useStore from '../../lib/store'

/**
 * This is the Main component, that will be visible when landing on this site
 */

const Main = () => {
  const rightPanel = useStore(state => state.rightPanel);

  return (
    <div className="Main">
      <MainMap/>
      <Chronoview/>
      <SidePanel position="left"><MapSideSearch/></SidePanel>
      <SidePanel position="right">
        {rightPanel.type === 'site' ? <SiteComponent id={parseInt(rightPanel.id)}/> : <React.Fragment/>}
        {rightPanel.type === 'help' ? <Help /> : <React.Fragment/>}
        {rightPanel.type === 'project' ? <PagesProject code={rightPanel.id}/> : <React.Fragment/>}
        {rightPanel.type === 'legend' ? <Legend code={rightPanel.id}/> : <React.Fragment/>}
        {rightPanel.type === 'chronology' ? <PanelChronology id={rightPanel.id}/> : <React.Fragment/>}
        {rightPanel.type === 'shp' ? <PanelShapefile id={rightPanel.id}/> : <React.Fragment/>}
        {rightPanel.type === 'db' ? <PanelDataset id={parseInt(rightPanel.id)}/> : <React.Fragment/>}
      </SidePanel>
    </div>
  )
}
//<DatabasesList isPublic={true}/>

export default Main
