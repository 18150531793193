import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

// react router
import { useSearchParams } from "react-router-dom";

// zustand
import useStore, { useValidatedSearchStore, defaults, useSearchStore } from '../../lib/store'
import {shallow} from 'zustand/shallow'
//

import _ from "underscore";

import config from '../../config.json';

const subseparator = '§';

const StoreRoute = props => {

  const [ searchParams, setSearchParams ] = useSearchParams();

  const [
    sidePanelOpened, setSidePanelOpened,
    rightPanel, setRightPanel,
    lng, setLng,
    lat, setLat,
    zoom, setZoom,
    chronologies,
    mapUpdated,
  ] = useStore(state => [
    state.sidePanelOpened, state.setSidePanelOpened,
    state.rightPanel, state.setRightPanel,
    state.lng, state.setLng,
    state.lat, state.setLat,
    state.zoom, state.setZoom,
    state.chronologies,
    state.mapUpdated,
  ], shallow);

  const [
    characSelectionCompacted, setCharacSelectionCompacted,
    selectedChronologyId, setSelectedChronologyId,
    chronologyStartDate, setChronologyStartDate,
    chronologyEndDate, setChronologyEndDate,
    chronologyFindIncludeUndetermined, setChronologyFindIncludeUndetermined,
    chronologyFindOnlyInside, setChronologyFindOnlyInside,
    selectedShapefiles, setSelectedShapefiles,
    knowledgeTypes, setKnowledgeTypes,
    centroid, setCentroid,
    exceptional, setExceptional,
    editors, setEditors,
    authors, setAuthors,
    databases, setDatabases,
    textual, setTextual,
    textualOn, setTextualOn,
  ] = useValidatedSearchStore(state  => [
    state.characSelectionCompacted, state.setCharacSelectionCompacted,
    state.selectedChronologyId, state.setSelectedChronologyId,
    state.chronologyStartDate, state.setChronologyStartDate,
    state.chronologyEndDate, state.setChronologyEndDate,
    state.chronologyFindIncludeUndetermined, state.setChronologyFindIncludeUndetermined,
    state.chronologyFindOnlyInside, state.setChronologyFindOnlyInside,
    state.selectedShapefiles, state.setSelectedShapefiles,
    state.knowledgeTypes, state.setKnowledgeTypes,
    state.centroid, state.setCentroid,
    state.exceptional, state.setExceptional,
    state.editors, state.setEditors,
    state.authors, state.setAuthors,
    state.databases, state.setDatabases,
    state.textual, state.setTextual,
    state.textualOn, state.setTextualOn,
  ], shallow);

  const [
    copyFromValidated,
  ] = useSearchStore(state  => [
    state.copyFromValidated,
  ], shallow);

  /**
   * useEffect executed when loading params from url
   */
  useEffect(() => {
    const params = {};
    let validateSearch = false;
    //searchParams.forEach((param,k) => params[k] = param);
    new URLSearchParams(window.location.search).forEach((param,k) => params[k] = param);
    //console.log("url params : ", params);

    const convertFromUrlstring = s => s;
    const convertFromUrlBool = b => b == '1' ? true : (b == '0' ? false : null);
    const convertFromUrlInt = parseInt
    const convertFromUrlFloat = parseFloat
    const convertFromUrlStringArray = v => v.split(subseparator).map(e => e);
    const convertFromUrlIntArray = v => v.split(subseparator).map(e => parseInt(e));
    const convertFromUrlRightPanel = rp => {
      const splited = rp.split(subseparator);
      const param_rp = {}
      if (splited.length >= 1) param_rp.type=splited[0];
      if (splited.length == 2) param_rp.id=splited[1];
      return param_rp;
    }

    const uSet = (urlKey, storeKey, storeValue, storeSet, convertFromUrl = convertFromUrlstring) => {
      if (urlKey in params) {
        const v = convertFromUrl(params[urlKey])
        if (!_.isEqual(v, storeValue)) {
          //console.log(`set ${storeKey} to `, v);
          storeSet(v);
          validateSearch = true;
        }
      } else {
        if (!_.isEqual(storeValue, defaults[storeKey])) {
          //console.log(`reset ${storeKey} to `, defaults[storeKey]);
          storeSet(defaults[storeKey]);
          validateSearch = true;
        }
      }
    }

    try {
      uSet('p', 'sidePanelOpened', sidePanelOpened, setSidePanelOpened, convertFromUrlstring);
      uSet('rp', 'rightPanel', rightPanel, v => setRightPanel(v, false), convertFromUrlRightPanel);
      uSet('lng', 'lng', lng, setLng, convertFromUrlFloat);
      uSet('lat', 'lat', lat, setLat, convertFromUrlFloat);
      uSet('z', 'zoom', zoom, setZoom, convertFromUrlFloat);
      uSet('cha', 'characSelectionCompacted', characSelectionCompacted, setCharacSelectionCompacted, convertFromUrlIntArray);
      uSet('c', 'selectedChronologyId', selectedChronologyId, setSelectedChronologyId, convertFromUrlInt);
      uSet('sd', 'chronologyStartDate', chronologyStartDate, setChronologyStartDate, convertFromUrlInt);
      uSet('ed', 'chronologyEndDate', chronologyEndDate, setChronologyEndDate, convertFromUrlInt);
      uSet('iu', 'chronologyFindIncludeUndetermined', chronologyFindIncludeUndetermined, setChronologyFindIncludeUndetermined, convertFromUrlBool);
      uSet('oi', 'chronologyFindOnlyInside', chronologyFindOnlyInside, setChronologyFindOnlyInside, convertFromUrlBool);
      uSet('shps', 'selectedShapefiles', selectedShapefiles, setSelectedShapefiles, convertFromUrlIntArray);
      uSet('kt', 'knowledgeTypes', knowledgeTypes, setKnowledgeTypes, convertFromUrlStringArray);
      uSet('ce', 'centroid', centroid, setCentroid, convertFromUrlBool);
      uSet('exceptional', 'exceptional', exceptional, setExceptional, convertFromUrlBool);
      uSet('editors', 'editors', editors, setEditors, convertFromUrlStringArray);
      uSet('authors', 'authors', authors, setAuthors, convertFromUrlIntArray);
      uSet('db', 'databases', databases, setDatabases, convertFromUrlIntArray);
      uSet('textual', 'textual', textual, setTextual, convertFromUrlstring);
      uSet('textualOn', 'textualOn', textualOn, setTextualOn, convertFromUrlstring);

      if (validateSearch)
        copyFromValidated();

    } catch (err) {
      console.error(err);
    }
  }, [ searchParams ]);

  /**
   * useEffect executed when params changed, and we have to rewrite the url with thems
   */
  useEffect(() => {
    const params = {};
    //searchParams.forEach((param,k) => params[k] = param);
    new URLSearchParams(window.location.search).forEach((param,k) => params[k] = param);
    const { sidePanelOpened, rightPanel } = useStore.getState();

    // here, we dont touch to lng/lat/zoom, so we takes theses values from url back
    const settings = _.pick(params, ['lng', 'lat', 'z']);

    const convertToUrlString = v => `${v}`;
    const convertToUrlInt = v => `${v}`;
    const convertToUrlFloat = v => `${v}`;
    const convertToUrlBool = b => b ? '1' : '0';
    const convertToUrlIntArray = v => v.join(subseparator);
    const convertToUrlStringArray = v => v.join(subseparator);
    const convertToUrlRightPanel = v => `${v.type}${v.id ? subseparator+v.id : ''}`;

    const uSet = (urlKey, storeKey, storeValue, convertToUrl) => {
      if (!['sidePanelOpened', 'rightPanel'].includes(storeKey)) {
        let storeValues = useValidatedSearchStore.getState();
        //console.log("storeValues", storeValues)
        storeValue = storeValues[storeKey];
      }
      if (!_.isEqual(defaults[storeKey], storeValue)) {
        //console.log(`set in url (${storeKey} => ${urlKey}) value: `, storeValue, convertToUrl(storeValue));
        settings[urlKey] = convertToUrl(storeValue);
      } else {
        //console.log(`reset in url (${storeKey} => ${urlKey}) storeValue: `, storeValue);
        delete settings[urlKey];
      }
    }

    uSet('p', 'sidePanelOpened', sidePanelOpened, convertToUrlString);
    uSet('rp', 'rightPanel', rightPanel, convertToUrlRightPanel);
    uSet('c', 'selectedChronologyId', selectedChronologyId, convertToUrlInt);
    uSet('cha', 'characSelectionCompacted', characSelectionCompacted, convertToUrlIntArray);
    uSet('sd', 'chronologyStartDate', chronologyStartDate, convertToUrlInt);
    uSet('ed', 'chronologyEndDate', chronologyEndDate, convertToUrlInt);
    uSet('iu', 'chronologyFindIncludeUndetermined', chronologyFindIncludeUndetermined, convertToUrlBool);
    uSet('oi', 'chronologyFindOnlyInside', chronologyFindOnlyInside, convertToUrlBool);
    uSet('shps', 'selectedShapefiles', selectedShapefiles, convertToUrlIntArray);
    uSet('kt', 'knowledgeTypes', knowledgeTypes, convertToUrlStringArray);
    uSet('ce', 'centroid', centroid, convertToUrlBool);
    uSet('exceptional', 'exceptional', exceptional, convertToUrlBool);
    uSet('editors', 'editors', editors, convertToUrlStringArray);
    uSet('authors', 'authors', authors, convertToUrlIntArray);
    uSet('db', 'databases', databases, convertToUrlIntArray);
    uSet('textual', 'textual', textual, convertToUrlString);
    uSet('textualOn', 'textualOn', textualOn, convertToUrlString);

    //console.log("oth params : ", params, settings, !_.isEqual(params, settings));
    if (!_.isEqual(params, settings)) {
      setSearchParams(settings);
    }
  }, [
    sidePanelOpened,
    rightPanel,
    characSelectionCompacted,
    selectedChronologyId,
    chronologyStartDate,
    chronologyEndDate,
    chronologyFindIncludeUndetermined,
    chronologyFindOnlyInside,
    selectedShapefiles,
    knowledgeTypes,
    centroid,
    exceptional,
    editors,
    authors,
    databases,
    textual,
    textualOn,
  ]);

  /**
   * useEffect speciffically for lat/lng/zoom, and we have to rewrite the url with thems
   */
  useEffect(() => {
    const params = {};
    //searchParams.forEach((param,k) => params[k] = param);
    new URLSearchParams(window.location.search).forEach((param,k) => params[k] = param);

    const { lat, lng, zoom } = useStore.getState(); // override because reacted values are not yet updated

    // here, we touch only lng/lat/zoom, so we takes others values from url back
    const settings = _.omit(params, ['lng', 'lat', 'z']);

    if (defaults['lng'] !== lng) settings['lng']=''+lng;
    if (defaults['lat'] !== lat) settings['lat']=''+lat;
    if (defaults['zoom'] !== zoom) settings['z']=''+zoom;

    //const oldParams = _.pick(params, ['lng', 'lat', 'z']);
    //console.log("coords params : ", params, settings, !_.isEqual(params, settings));
    if (!_.isEqual(params, settings)) {
      setSearchParams(settings);
    }
  }, [ mapUpdated ]);

  return (<React.Fragment />);
}

StoreRoute.propTypes = {}

export default StoreRoute
