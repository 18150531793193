import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import config from '../../config.json';

import './MainMap.scss'
import Loader from '../Loader/Loader';
import ReactMap from './ReactMap';

import { basemaps } from '../../lib/map/basemaps';

// zustand
import useStore, { useValidatedSearchStore } from '../../lib/store'
import { shallow } from 'zustand/shallow'

// graphql
import { useQuery } from '@apollo/client'
import { GET_SITES_QUERY, WhereSiteSearch } from '../../lib/queries/sites'

import { initClusters, loadClusters, loadSpiderfy, unloadCluster } from '../../lib/map/clusters';
import { UNDETERMINED_LEFT } from '../../lib/year';
import { getChronologyByDatel1, getChronologyByDatel4 } from '../../lib/chronologiesSelection';

import _ from 'underscore';
import { getInLang } from '../../lib/translate';
//import MyReactControl from './MyReactControl';
//import MapButtonHack from './MapButtonHack';

const MainMapManager = props => {
  const { map } = props;

  const [
    basemap,
    sidePanelOpened,
    lng, setLng,
    lat, setLat,
    zoom, setZoom,
    setMapObject,
    setMapUpdated,
    allMapDrawed, setAllMapDrawed,
    chronologies,
  ] = useStore(state => [
    state.basemap,
    state.sidePanelOpened,
    state.lng, state.setLng,
    state.lat, state.setLat,
    state.zoom, state.setZoom,
    state.setMapObject,
    state.setMapUpdated,
    state.allMapDrawed, state.setAllMapDrawed,
    state.chronologies,
  ], shallow)

  const [
    geojsonResult,
    setGeojsonResult,
    selectedChronologyId,
    characSelectionCompacted,
    chronologySelectionCompacted,
    selectedShapefiles,
    knowledgeTypes,      // if it change, update the map
    exceptional,         // if it change, update the map
    centroid,            // if it change, update the map
    editors,             // if it change, update the map
    authors,             // if it change, update the map
    databases,           // if it change, update the map
    textual,             // if it change, update the map
    textualOn,           // if it change, update the map
    chronologyStartDate, // if it change, update the map
    chronologyEndDate,   // if it change, update the map
    chronologyFindIncludeUndetermined,   // if it change, update the map
    chronologyFindOnlyInside,   // if it change, update the map
  ] = useValidatedSearchStore(state => [
    state.geojsonResult,
    state.setGeojsonResult,
    state.selectedChronologyId,
    state.characSelectionCompacted,
    state.chronologySelectionCompacted,
    state.selectedShapefiles,
    state.knowledgeTypes,
    state.exceptional,
    state.centroid,
    state.editors,
    state.authors,
    state.databases,
    state.textual,
    state.textualOn,
    state.chronologyStartDate,
    state.chronologyEndDate,
    state.chronologyFindIncludeUndetermined,
    state.chronologyFindOnlyInside,
  ]);

  const [ inited, setInited ] = useState(false);
  const [ gqlQuery, setGqlQuery ] = useState(GET_SITES_QUERY);
  const pois = useQuery(gqlQuery); // pois = { loading, error, data }


  // init
  useEffect(() => {
    if (window.mapinited) return;
    window.mapinited=true;
    if (inited) return;

    /*
    map.addControl(new MyReactControl({
      element: <MapButtonHack map={map} loader={true}></MapButtonHack>
    }), 'bottom-right');

    map.addControl(new MyReactControl({
      element: <MapButtonHack map={map} loader={false}></MapButtonHack>
    }), 'bottom-right');
    */

    initClusters(map).then(() => {
      setInited(true);
      setMapObject(map);
      map.on('idle', () => {
        //console.log("idle");
        setMapUpdated();
      });
    });

  }, []);

  // change basemap
  useEffect(() => {
    if (!inited) return;
    //console.log("basemap change");
    unloadCluster(map);
    //console.log("setStyle: ", basemaps[basemap].url);

    map.setStyle(basemaps[basemap].url, {
      diff: false,
    });

    initClusters(map).then(() => {
        map.once('idle', () => {
          loadArkeoSites();
        });
    });

  }, [basemap]);

  useEffect(() => {
    if (!inited) return;
    if (!geojsonResult) return;
    //console.log("geojsonResult changed, so loadArkeoSites()")
    loadArkeoSites();
  }, [geojsonResult, inited]);

  useEffect(() => {
    if (pois.loading) { /*console.log("no geojson : loading...")*/; return; }
    if (pois.error) { console.log("no geojson : error ", pois.error); return; }
    if (!pois.data)  { console.log("no geojson : no data ", pois.data); return; }

    unloadCluster(map);

    const selectedChronology = chronologies.find(c => c.id === selectedChronologyId);

    const geojson_result = {
      "type": "FeatureCollection",
      "features": pois.data && pois.data.ako_site ? pois.data.ako_site.map(site => {
        const p1 = getChronologyByDatel1(selectedChronology.chronologies, site.end_date1, site.end_date2);
        const p2 = getChronologyByDatel4(selectedChronology.chronologies, site.end_date1, site.end_date2);
        return {
          "type": "Feature",
          "properties": {
            "name": site.name,
            //"mag": site.end_date1,
            //"dates": [ site.start_date1, site.start_date2, site.end_date1, site.end_date2],
            "start_date1": site.start_date1,
            "start_date2": site.start_date2,
            "end_date1": site.end_date1,
            "end_date2": site.end_date2,
            "periodeid": p1 ? p1.id : (site.end_date1 === UNDETERMINED_LEFT ? config.chronology.undetermined.id : config.chronology.outside.id),
            "periodeid2": p2 ? p2.id : (site.end_date1 === UNDETERMINED_LEFT ? config.chronology.undetermined.id : config.chronology.outside.id),
            "color": p2 ? '#'+p2.color : (site.end_date1 === UNDETERMINED_LEFT ? config.chronology.undetermined.color : config.chronology.outside.color),
            "exceptional": site.site_ranges_aggregate.aggregate.count > 0 ? 'yes' : 'no',
            "site_id": site.id,
          },
          "geometry": {
            "type": "Point",
            "coordinates": site.geom.coordinates
          }
        }
      }) : []
    }
    //console.log("setGeojsonResult......................")
    setGeojsonResult(geojson_result);
  }, [pois.data, pois.loading, pois.error, setGeojsonResult]);

  /**
   * listen to validated search parameters, so we query again the server
   */
  useEffect(() => {
    //console.log("NEW QUERY")
    const whereSiteSearch = new WhereSiteSearch();
    setGqlQuery(whereSiteSearch.getGqlQuery());
  }, [
    selectedChronologyId,
    characSelectionCompacted,
    chronologySelectionCompacted,
    knowledgeTypes,
    exceptional,
    centroid,
    editors,
    authors,
    databases,
    textual,
    textualOn,
    chronologyStartDate,
    chronologyEndDate,
    chronologyFindIncludeUndetermined,
    chronologyFindOnlyInside,
    selectedShapefiles,
    selectedChronologyId,
  ]);

  const loadArkeoSites = () => {
    setAllMapDrawed(false);
    unloadCluster(map);

    if (!geojsonResult) return;

    /* remove old data */
    const style = map.getStyle();

    //const layersToRemove = style.layers.filter(layer => !layer.id.startsWith('arkeosite_')).map(layer => layer.id);
    const layersToRemove = style.layers.filter(layer => layer.id.startsWith('arkeosite_') || layer.id.startsWith('arkeoshp-')).map(layer => layer.id);
    //const layersToRemove = style.layers.map(layer => layer.id);
    //console.log("layersToRemove", layersToRemove);

    // we remove all sources, except our
    //const sourcesToRemove = _.keys(style.sources).filter(id => id !== 'arkeosites');
    const sourcesToRemove = _.keys(style.sources).filter(id => id === 'arkeosites' || id.startsWith('arkeoshp-src-'));
    //const sourcesToRemove = _.keys(style.sources);
    //console.log("sourcesToRemove", sourcesToRemove);

    // remove all layers and sources
    layersToRemove.forEach(id => map.removeLayer(id));
    sourcesToRemove.forEach(id => map.removeSource(id));

    loadShapefiles();

    /* add new data */
    //console.log("LOAAAAAAAAAAAAAAAAAAD CLUSTERS")
    loadClusters(map, geojsonResult)

    const style2 = map.getStyle();
    //console.log("style2", style2);

    map.once('idle', () => {
      //loadSpiderfy(map);
      setAllMapDrawed(true);
    })
  }

  // change arkeosites
  useEffect(() => {
    loadArkeoSites();
  }, [geojsonResult]);

  const loadShapefiles = () => {
    // add shapefiles
    if (pois.data && pois.data.ako_shapefile) {
      //console.log("adding shapefiles...", pois.data.ako_shapefile);
      pois.data.ako_shapefile.forEach(shapefile => {
        const geojson_shp = JSON.parse(shapefile.geojson);
        // hack
        //geojson_shp.features = geojson_shp.features.slice(40,200);
        //console.log("geojson_shp", geojson_shp);

        try {
          map.addSource(`arkeoshp-src-${shapefile.id}`, {
            type: 'geojson',
            data: geojson_shp,
            'attribution': getInLang(shapefile.shapefile_trs).attribution,
          });
        } catch (err) {
          console.log("shapefile add source error : ", err);
        }

        map.addLayer({
          id: `arkeoshp-fill-${shapefile.id}`,
          type: 'fill',
          source: `arkeoshp-src-${shapefile.id}`,
          filter: ['==', '$type', 'Polygon'],
          layout: {
          },
          paint: {
            'fill-color': '#0080ff', // blue color fill
            'fill-opacity': 0.3,
          }
        })

        map.addLayer({
          'id': `arkeoshp-outline-${shapefile.id}`,
          'type': 'line',
          'source': `arkeoshp-src-${shapefile.id}`,
          'layout': {},
          //filter: ['==', '$type', 'LineString'],
          'paint': {
            'line-color': '#000',
            'line-width': 3
          }
        });
      })
    }
  }

  return (
    <React.Fragment>
      {pois.loading ? <Loader /> : ''}
    </React.Fragment>
  )
}

/**
 * MainMap is the Main Map of the application
 * @param {} props
 */

const MainMap = props => {
  const [ map, setMap ] = useState(null);
  const [
    lng, setLng,
    lat, setLat,
    zoom, setZoom,
  ] = useStore(state => [
    state.lng, state.setLng,
    state.lat, state.setLat,
    state.zoom, state.setZoom,
  ], shallow)

  const onMove=evt => {
    setLng(parseFloat(evt.viewState.longitude.toFixed(5)));
    setLat(parseFloat(evt.viewState.latitude.toFixed(5)));
    setZoom(parseFloat(evt.viewState.zoom.toFixed(2)));
  }

  return (
    <React.Fragment>
      <ReactMap onMap={map => setMap(map)} onMove={onMove} viewState={{longitude: lng, latitude: lat, zoom}}/>
      { map !== null ? <MainMapManager map={map}/> : ''}
    </React.Fragment>
  )

  /*
  return (
    <div className="MainMap">
      <ReactMap Manager={MainMapManager}/>
    </div>
  )
  */
}

MainMap.propTypes = {
}

export default MainMap
