import React from 'react'
//import PropTypes from 'prop-types'

//import Button from 'react-bootstrap/Button';
import { BiSearch } from 'react-icons/bi'

// zustand
import { useSearchStore } from '../../lib/store'
import {shallow} from 'zustand/shallow'
//

// bootstrap
import Form from 'react-bootstrap/Form';
//import FormControl from 'react-bootstrap/FormControl';
//

// i18n
import { useTranslation } from 'react-i18next';
//import { getInLang } from '../../lib/translate';
//

// graphql queries
import { useQuery } from '@apollo/client'
import { GetDatabases, GetDatabasesAuthors, GetDatabasesEditors } from '../../lib/queries/database';
//



import './FormSearchOthers.scss'
import FlatSelect from '../FlatSelect/FlatSelect';

const SelectEditors = () => {
  const { t/*, i18n*/ } = useTranslation();
  const {loading, error, data} = useQuery(GetDatabasesEditors, {
    variables: {
    }
  });

  const [
    editors, updateEditors,
  ] = useSearchStore(state => [
    state.editors, state.updateEditors,
  ], shallow);

  if (error) return <div className="error">Error</div>
  if (loading) return <div className="loading">...</div>

  return (
    <FlatSelect
      unselectable={true}
      label={t('components.FormSearchOthers.editors.label')}
      partiallySelected={editors.length > 0}
    >
      {data.ako_database.filter(d => d.editor !== '').map(database =>
        <FlatSelect
          key={database.editor}
          leaf={true}
          label={database.editor}
          selected={editors.includes(database.editor)}
          onSelect={sel => updateEditors(sel, database.editor)}
        />
      )}
    </FlatSelect>
  );
}

const SelectAuthors = props => {
  const { t, i18n } = useTranslation();
  const {loading, error, data} = useQuery(GetDatabasesAuthors, {
    variables: {
    }
  });

  const [
    authors, updateAuthors,
  ] = useSearchStore(state => [
    state.authors, state.updateAuthors,
  ], shallow);

  if (error) return <div className="error">Error</div>
  if (loading) return <div className="loading">...</div>

  const authorsindb = data.ako_database__authors.map(author => {
    return {
      user_id: author.user_id,
      fullname: `${author.user.lastname} ${author.user.firstname}`,
    }
  });

  return (
    <FlatSelect
      unselectable={true}
      label={t('components.FormSearchOthers.authors.label')}
      partiallySelected={authors.length > 0}
      >
      {authorsindb.sort((a,b) => a.fullname.localeCompare(b.fullname, i18n.language, { sensitivity: 'base' })).map(author => {
        return <FlatSelect
          key={author.user_id}
          leaf={true}
          label={author.fullname}
          selected={authors.includes(author.user_id)}
          onSelect={sel => updateAuthors(sel, author.user_id)}
        />
      })}
    </FlatSelect>
  );
}

const SelectDatabases = () => {
  const { t/*, i18n*/ } = useTranslation();
  const {loading, error, data} = useQuery(GetDatabases, {
    variables: {
    }
  });

  const [
    databases, updateDatabases,
  ] = useSearchStore(state => [
    state.databases, state.updateDatabases,
  ], shallow);

  if (error) return <div className="error">Error</div>
  if (loading) return <div className="loading">...</div>

  return (
    <FlatSelect
      unselectable={true}
      label={t('components.FormSearchOthers.databases.label')}
      partiallySelected={databases.length > 0}
    >
      {data.ako_database.map(database => {
        return <FlatSelect
          key={database.id}
          leaf={true}
          label={database.name}
          selected={databases.includes(database.id)}
          onSelect={sel => updateDatabases(sel, database.id)}
        />
      })}
    </FlatSelect>
  );
}

const FormSearchOthers = () => {
  const { t/*, i18n*/ } = useTranslation();
  const [
    knowledgeTypes, updateKnowledgeType,
    centroid, setCentroid,
    exceptional, setExceptional,
    textual, setTextual,
    textualOn, setTextualOn,
  ] = useSearchStore(state => [
    state.knowledgeTypes, state.updateKnowledgeTypes,
    state.centroid, state.setCentroid,
    state.exceptional, state.setExceptional,
    state.textual, state.setTextual,
    state.textualOn, state.setTextualOn,
  ], shallow);

  return (
    <div className="FormSearchOthers">
      <Form.Label>
        {t("components.FormSearchOthers.label")}
      </Form.Label>

      <div className="List">

        <FlatSelect
          unselectable={true}
          label={t('components.FormSearchOthers.knowledge_type.label')}
          partiallySelected={knowledgeTypes.length > 0}
        >
          <FlatSelect
            leaf={true}
            label={t('arkeo.knowledge_type.not_documented')}
            selected={knowledgeTypes.includes('not_documented')}
            onSelect={sel => updateKnowledgeType(sel, 'not_documented')}
          />
          <FlatSelect
            leaf={true}
            label={t('arkeo.knowledge_type.literature')}
            selected={knowledgeTypes.includes('literature')}
            onSelect={sel => updateKnowledgeType(sel, 'literature')}
          />
          <FlatSelect
            leaf={true}
            label={t('arkeo.knowledge_type.prospected_aerial')}
            selected={knowledgeTypes.includes('prospected_aerial')}
            onSelect={sel => updateKnowledgeType(sel, 'prospected_aerial')}
          />
          <FlatSelect
            leaf={true}
            label={t('arkeo.knowledge_type.prospected_pedestrian')}
            selected={knowledgeTypes.includes('prospected_pedestrian')}
            onSelect={sel => updateKnowledgeType(sel, 'prospected_pedestrian')}
          />
          <FlatSelect
            leaf={true}
            label={t('arkeo.knowledge_type.surveyed')}
            selected={knowledgeTypes.includes('surveyed')}
            onSelect={sel => updateKnowledgeType(sel, 'surveyed')}
          />
          <FlatSelect
            leaf={true}
            label={t('arkeo.knowledge_type.dig')}
            selected={knowledgeTypes.includes('dig')}
            onSelect={sel => updateKnowledgeType(sel, 'dig')}
          />
        </FlatSelect>

        <FlatSelect
          unselectable={true}
          label={t('components.FormSearchOthers.precision.label')}
          partiallySelected={centroid !== null}
        >
          <FlatSelect
            leaf={true}
            label={t('arkeo.precision.precise')}
            selected={centroid === false}
            onSelect={sel => setCentroid(sel ? false : null)}
          />
          <FlatSelect
            leaf={true}
            label={t('arkeo.precision.not-precise')}
            selected={centroid === true}
            onSelect={sel => setCentroid(sel ? true : null)}
          />
        </FlatSelect>

        <FlatSelect
          unselectable={true}
          label={t('components.FormSearchOthers.exceptional.label')}
          partiallySelected={exceptional !== null}
        >
          <FlatSelect
            leaf={true}
            label={t('arkeo.exceptional.exceptional')}
            selected={exceptional === true}
            onSelect={sel => setExceptional(sel ? true : null)}
          />
          <FlatSelect
            leaf={true}
            label={t('arkeo.exceptional.not-exceptional')}
            selected={exceptional === false}
            onSelect={sel => setExceptional(sel ? false : null)}
          />
        </FlatSelect>

        <SelectEditors />

        <SelectAuthors />

        <SelectDatabases />

        <Form.Label>
          {t("components.FormSearchOthers.textual.label")}
        </Form.Label>

        <div className="TextualSearch">
          <Form.Control
            type="text"
            placeholder={t('components.FormSearchOthers.textual.placeholder')}
            value={textual}
            onChange={e => setTextual(e.target.value)}
          />
          <BiSearch className='icon'/>
        </div>

        <Form.Check
          inline
          label={t('components.FormSearchOthers.textual.search-on.all')}
          type='radio'
          checked={textualOn === 'all'}
          onChange={() => {}}
          onClick={() => setTextualOn('all')}
        />
        <Form.Check
          inline
          label={t('components.FormSearchOthers.textual.search-on.dataset-name')}
          type='radio'
          checked={textualOn === 'dataset-name'}
          onChange={() => {}}
          onClick={() => setTextualOn('dataset-name')}
        />
        <Form.Check
          inline
          label={t('components.FormSearchOthers.textual.search-on.resource-name')}
          type='radio'
          checked={textualOn === 'resource-name'}
          onChange={() => {}}
          onClick={() => setTextualOn('resource-name')}
        />
        <Form.Check
          inline
          label={t('components.FormSearchOthers.textual.search-on.comments')}
          type='radio'
          checked={textualOn === 'comments'}
          onChange={() => {}}
          onClick={() => setTextualOn('comments')}
        />
        <Form.Check
          inline
          label={t('components.FormSearchOthers.textual.search-on.bibliography')}
          type='radio'
          checked={textualOn === 'bibliography'}
          onChange={() => {}}
          onClick={() => setTextualOn('bibliography')}
        />
      </div>

    </div>
  )
}

FormSearchOthers.propTypes = {}

export default FormSearchOthers
