// react
import React from 'react';
//import PropTypes from 'prop-types';
//

// bootstrap
import Form from 'react-bootstrap/Form';
//

// i18n
import { useTranslation } from 'react-i18next';
//import { getInLang } from '../../lib/translate';
//

// Components
import CharacMenu from './CharacMenu';
//

// zustand
import useStore from '../../lib/store'
//import {shallow} from 'zustand/shallow'
//

import './FormSearchWhat.scss';

/**
 * The 'what' Tab in the Search Form (left side bar)
 * @param {*} props
 * @returns The 'What' Component
 */
function FormSearchWhat() {
  const { t, i18n } = useTranslation();
  const characs = useStore(state => state.characs);

  return (
    <div className="FormSearchWhat">
      <Form.Label>
        {t("components.FormSearchWhat.label")}
      </Form.Label>

      {characs.map(charac =>
        <CharacMenu
          key={charac.id}
          characs={characs}
          charac={charac}
          lang={i18n.language}
          path={[charac.id]}
        />
      )}

    </div>
  );
}

export default FormSearchWhat;
