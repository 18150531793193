import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';

import React from 'react'
//import PropTypes from 'prop-types'

import { BiSearch } from 'react-icons/bi'

import FormSearchWhen from '../FormSearchWhen/FormSearchWhen';
import FormSearchWhat from '../FormSearchWhat/FormSearchWhat';
import FormSearchWhere from '../FormSearchWhere/FormSearchWhere';
import FormSearchOthers from '../FormSearchOthers/FormSearchOthers';

import { RiArrowGoBackLine } from 'react-icons/ri';

// i18n
import { useTranslation } from 'react-i18next';

// zustand
import useStore, { useSearchStore, useValidatedSearchStore } from '../../lib/store'
//import {shallow} from 'zustand/shallow'
//

import './MapSideSearch.scss';

/**
 * MapSideSearchButtons are the buttons of the left search sidebar
 * @param {*} props (none)
 * @returns The component
 */
const MapSideSearchButtons = props => {
  const { t/*, i18n*/ } = useTranslation();

  const setSidePanelOpened = useStore(state => state.setSidePanelOpened);
  const clearSearch = useSearchStore(state => state.clearSearch);
  const validateSearch = useValidatedSearchStore(state => state.validateSearch);

  const doTheSearch = () => {
    validateSearch();
    setSidePanelOpened('none');
  }

  return (
    <div className="SearchButtons">
      <Button className="SearchResetButton big" variant="light" onClick={clearSearch}><RiArrowGoBackLine/></Button>
      <Button className="SearchButton big" variant="light" onClick={doTheSearch}>{t('components.MapSideSearch.button-validateSearch.label')}</Button>
    </div>
  );
}

/**
 * This is the left side search component inside the SideBar. It contain all tabbed components (when, what, where, etc.)
 * @param {*} props (none)
 * @returns The Map Side Search Component
 */
const MapSideSearch = props => {
  const { t/*, i18n*/ } = useTranslation();

  return (
    <div className="MapSideSearch">
      <div className="SearchForm">
        <div className="SearchHeader">
          <div className="SaerchHeaderTitle">{t('components.MapSideSearch.title')}</div>
          <div className="SaerchHeaderButtons">
            <button className="SearchButton"><BiSearch/></button>
          </div>
        </div>
        <Tabs
          defaultActiveKey="when"
          id="uncontrolled-tab-example"
          className="mb-3"
          fill
        >
          <Tab eventKey="when" title={<div className='PedroTab'>{t('components.MapSideSearch.when.title')}</div>}>
            <FormSearchWhen />
          </Tab>
          <Tab eventKey="what" title={<div className='PedroTab'>{t('components.MapSideSearch.what.title')}</div>}>
            <FormSearchWhat />
          </Tab>
          <Tab eventKey="where" title={<div className='PedroTab'>{t('components.MapSideSearch.where.title')}</div>}>
            <FormSearchWhere />
          </Tab>
          <Tab eventKey="other" title={<div className='PedroTab'>{t('components.MapSideSearch.other.title')}</div>}>
            <FormSearchOthers />
          </Tab>
        </Tabs>
      </div>
      <MapSideSearchButtons />
    </div>
  )
}

MapSideSearch.propTypes = {}

export default MapSideSearch
