import React from 'react'
//import PropTypes from 'prop-types'
import ShareButton from '../ShareButton/ShareButton'

import { GetFullShapefile } from '../../lib/queries/shapefile'

import { useQuery } from '@apollo/client';

import Linkify from 'react-linkify';

// i18n
import { useTranslation } from 'react-i18next';
import { getInLang } from '../../lib/translate';

import './PanelShapefile.scss';
import Loader from '../Loader/Loader';
import MiniMap from '../MiniMap/MiniMap';
import Markdown from '../Markdown/Markdown'
import { yearToHuman } from '../../lib/year';
import CloseButton from '../CloseButton/CloseButton';

const myLink = (decoratedHref, decoratedText, key) => {
  return (
    <a href={decoratedHref} key={key} target='_blank' rel="noreferrer">
      {decoratedText}
    </a>
  );
};

const PanelShapefile = props => {
  const { t, i18n } = useTranslation();
  const { id } = props;

  const shapefileq = useQuery(GetFullShapefile, {
    variables: {
      shapefile_id: id,
    }
  }) // shapefile = { loading, error, data }

  if (shapefileq.loading) return <div className="PanelShapefile"><Loader /></div>
  if (shapefileq.error) return <div className="PanelShapefile"><div className="Error">{shapefileq.error.message}</div></div>

  if (!shapefileq.data.ako_shapefile || shapefileq.data.ako_shapefile.length === 0)
    return <div className="PanelShapefile"><div className="Error">Shapefile not found</div></div>

  const shapefile = shapefileq.data.ako_shapefile[0];
  //console.log("shapefile data !", shapefile);


  //console.log("geojson: ", geojson);

  return (
    <div className="PanelShapefile">
      <MiniMap geojson={JSON.parse(shapefile.geojson)}/>
      <CloseButton rp={{type: 'legend', id: 'where'}} />
      <div className="PanelShapefileContent">
        <div className="PanelShapefileHeader">
          <div className="HeaderTitle">{getInLang(shapefile.shapefile_trs, i18n.language).name}</div>
          <div className="HeaderButtons">
            <ShareButton />
          </div>
        </div>

        <div className="TwoColumns">
          <div className="LeftColumn">
            <h5>{t`components.PanelShapefile.temporal.title`}</h5>
            <table className="Chronos">
              <tbody>
                <tr>
                  <th>{t`components.PanelShapefile.temporal.begin.title`}</th>
                  <td className="date">{yearToHuman(shapefile.start_date)}</td>
                </tr>
                <tr>
                  <th>{t`components.PanelShapefile.temporal.end.title`}</th>
                  <td className="date">{yearToHuman(shapefile.end_date)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="RightColumn">
            <h5>{t`components.PanelShapefile.geographical.title`}</h5>
            {getInLang(shapefile.shapefile_trs, i18n.language).geographical_covering}
          </div>
        </div>

        <hr />

        <div className="TwoColumns">
          <div className="LeftColumn">
            <div><strong>{t`components.PanelShapefile.authors.title`}</strong> : {shapefile.shapefile__authors.map(ug => `${ug.user.lastname} ${ug.user.firstname}`).join(', ')}</div>
            <div><strong>{t`components.PanelShapefile.editors.title`}</strong> : {shapefile.editor_uri ? <a href={shapefile.editor_uri} target='_blank'>{shapefile.editor}</a> : shapefile.editor}</div>
            <div><strong>{t`components.PanelShapefile.type.title`}</strong> : SHP</div>
          </div>
          <div className="RightColumn">
            <div><strong>{t`components.PanelShapefile.date-created.title`}</strong> : {new Date(shapefile.created_at).toLocaleDateString(i18n.language)}</div>
            <div><strong>{t`components.PanelShapefile.date-updated.title`}</strong> : {new Date(shapefile.updated_at).toLocaleDateString(i18n.language)}</div>
          </div>
        </div>

        <hr />

        <div>
          <strong>{t`components.PanelShapefile.description.title`}</strong> : <div style={{whiteSpace: 'pre-wrap'}}><Markdown>{getInLang(shapefile.shapefile_trs, i18n.language).description}</Markdown></div>
        </div>

        <hr />

        <div>
          <strong>{t`components.PanelShapefile.Citation.title`}</strong> : <Linkify componentDecorator={myLink}>{getInLang(shapefile.shapefile_trs, i18n.language).citation}</Linkify>
        </div>

        <div>
          {/*<strong>{t`components.PanelShapefile.license.title`}</strong> : <a href="https://opendatacommons.org/norms/odc-by-sa/" target="_blank">Open Data Commons - ODC Attribution-Sharealike Community Norms</a>*/}
          <strong>{t`components.PanelShapefile.license.title`}</strong> : <a href={shapefile.licenseByLicenseId.url} target="_blank" rel="noreferrer">{shapefile.licenseByLicenseId.name}</a>
        </div>

      </div>
    </div>
  )
}

PanelShapefile.propTypes = {}

export default PanelShapefile
