import React, { useState, useEffect } from 'react'
//import PropTypes from 'prop-types'

// i18n
import { useTranslation } from 'react-i18next';

import useStore, { useValidatedSearchStore } from '../../lib/store'
import {shallow} from 'zustand/shallow'
import { getInLang } from '../../lib/translate';

import { basemaps } from '../../lib/map/basemaps';

const LegendWhere = () => {
  const { t, i18n } = useTranslation();

  const [ map, mapUpdated, allMapDrawed, basemap, shapefiles, setRightPanel ] = useStore(state => [
    state.mapObject, state.mapUpdated, state.allMapDrawed, state.basemap, state.shapefiles, state.setRightPanel,
  ], shallow)

  const [ selectedShapefiles ] = useValidatedSearchStore(state => [
    state.selectedShapefiles
  ], shallow)

  const [ bounds, setBounds ] = useState(null);

  //console.log("selectedShapefiles", selectedShapefiles);
  //console.log("shapefiles", shapefiles);

  useEffect(() => {
    if (map && allMapDrawed) {
      setBounds(map.getBounds());
    }
  }, [map, mapUpdated, allMapDrawed]);

  return (
    <div className="LegendWhere">
      <h2>{t`components.LegendWhere.coord.title`}</h2>

      <div className="Coords">
        <div className="Coord">
          <div className="Point">&nbsp;&bull;</div>
          <div className="Str"><pre>{bounds ? JSON.stringify(bounds, undefined, ' ') : ''}</pre></div>
        </div>
        <div className="Coord">
          <div className="Point">&nbsp;&bull;</div>
          <div className="Str">{basemaps[basemap].name}</div>
        </div>
      </div>

      <h2>{t`components.LegendWhere.include.title`}</h2>
      <div className="Coords">
      {selectedShapefiles.length === 0 ? (
        <div>{t`components.LegendWhere.include.none`}</div>
      ) : ''}
      {selectedShapefiles.map(shapefile_id => {
        const shapefile = shapefiles.find(shapefile => shapefile.id === shapefile_id)
        if (!shapefile) return <div key={shapefile.id}>unknwon shapefile id</div>
        return (
          <div key={shapefile.id} className="Coord">
            <div className="Point">&nbsp;&bull;</div>
            <div className="Str">{getInLang(shapefile.shapefile_trs, i18n.language).name}</div>
            <div className="Presentation">
              <a
                href='/#'
                rel="noreferer"
                onClick={
                  e => {
                    e.preventDefault();
                    setRightPanel({type: 'shp', id: shapefile.id})
                  }
                }
              >
                {t`components.LegendWhere.include.presentation`}
              </a>
            </div>
          </div>
        )
      })}
      </div>
    </div>
  )
}

LegendWhere.propTypes = {
}

export default LegendWhere
