import React, {useState} from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import PropTypes from "prop-types";

// i18n
import { useTranslation } from 'react-i18next';

// zustand
import useStore from '../../lib/store'
import { shallow } from 'zustand/shallow'

import { BsX } from 'react-icons/bs';

import './CloseButton.scss'

export const CloseButton = props => {
  const { t/*, i18n*/ } = useTranslation();
  const [ setSidePanelOpened, setRightPanel ] = useStore(state => [ state.setSidePanelOpened, state.setRightPanel], shallow)

  const { rp } = props;

  const close = e => {
    if (rp) {
      setRightPanel(rp);
    } else setSidePanelOpened('none');
  }

  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement='top'
      overlay={
        <Popover id={`popover-positioned-top`}>
          <Popover.Body>
            {t('components.CloseButton.popup.label')}
          </Popover.Body>
        </Popover>
      }
    >
      <button className="CloseButton" onClick={e => close(e)}>
        <BsX/>
      </button>
    </OverlayTrigger>
  )
}

CloseButton.propTypes = {
  /**
   * an optional object that represent the rightpage (see store.js), if set, will not close the side panel
   */
  rp: PropTypes.object,
}

export default CloseButton;
