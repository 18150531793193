import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Loader from '../Loader/Loader';
import Carousel from 'react-bootstrap/Carousel';

import Moment from 'react-moment';

// i18n
import { useTranslation } from 'react-i18next';
//

import useAxios from 'axios-hooks'

import './NakalaCarousel.scss';

const Error = props => {
  const { err } = props;
  return <h3>error: {err && err.message ? err.message : err}</h3>
}

const PhotoRendering = props => {
  const { url, onData } = props;
  const murl=url.match(/^https:\/\/(doi\.org|nakala\.fr)\/([0-9]+\.[0-9]+\/nkl\.[0-9a-z]+)$/);
  //console.log(url, murl);
  const identifier = murl && murl.length === 3 ? murl[2] : '10.34847/nkl.5b38j2bm';
  const apiurl=`https://api.nakala.fr/datas/${identifier}`;
  const [{ data, loading, error }/*, refetch*/] = useAxios(apiurl);

  useEffect(() => {
    if (data) onData(data);
  }, [ data, onData ]);

  if (loading) return <Loader />
  if (error) return <Error err={error}/>
  return (
    <img
      className="NakaPhoto"
      src={`https://api.nakala.fr/data/${data.identifier}/${data.files[0].sha1}`}
      alt={`${data.files[0].name}`}
    />
  )
}

const Photo = props => {
  const { url, selected, setPhotoData } = props;
  const [ data, setData ] = useState(null);
  if (!data && !selected) return <h3>Not Visible</h3>;
  if (data && selected) setPhotoData(data);
  return <PhotoRendering url={url} onData={d => setData(d)}/>
}

const Photos = props => {
  const { urls, setPhotoData } = props;
  const [ selectedIndex, setSelectedIndex ] = useState(0);

  const onSelect=(num,ev) => {
    setSelectedIndex(num);
  }

  return (
    <Carousel onSelect={onSelect} interval={null}>
      {urls.map((url, i) => (
        <Carousel.Item key={url}>
          <Photo url={url} selected={selectedIndex === i} setPhotoData={setPhotoData} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

const NakaMeta = props => {
  const { title, value, isUrl = false } = props;
  return (
    <div>
      <strong>{title} : </strong>
      {isUrl ? <a href={value} rel="noreferrer" target="_blank">{value}</a> : value}
    </div>
  )
}

const PhotoDesc = props => {
  const { data } = props;
  const loading = data == null;
  const { t/*, i18n*/ } = useTranslation();

  const getMeta = propertyUri => {
    const found = data.metas.find(meta => meta.propertyUri === propertyUri)
    if (found) return found.value;
    return '';
  }

  const getMetaCreator = propertyUri => {
    return data.metas
      .filter(meta => meta.propertyUri === propertyUri && meta.value)
      .map(e => e.value.fullName)
      .join(', ');
  }

  if (loading) {
    return (
      <div className="photoDesc">
        <Loader/>
      </div>
    )
  }

  return (
    <div className="photoDesc">
      <NakaMeta title={t("nakala.terms.title.title")} value={getMeta('http://nakala.fr/terms#title')}/>
      <NakaMeta title={t("nakala.terms.creators.title")} value={getMetaCreator('http://nakala.fr/terms#creator')}/>
      <NakaMeta title={t("nakala.terms.credate.title")} value={<Moment format="DD/MM/YYYY hh:mm">{data.creDate}</Moment>}/>
      <NakaMeta title={t("nakala.terms.license.title")} value={getMeta('http://nakala.fr/terms#license')}/>
      <NakaMeta title={t("nakala.terms.uri.title")} value={data.uri} isUrl={true}/>
      <br />
      <NakaMeta title={t("nakala.terms.description.title")} value={getMeta("http://purl.org/dc/terms/description")}/>
    </div>
  )
}

const PhotoHeader = props => {
  const { urls, showDescription } = props;
  const [ data, setData ] = useState(null);

  const setPhotoData = useCallback(_data => {
    setTimeout(() => {
      setData(_data);
    }, 0);
  }, [setData]);

  return (
    <div className="PhotoHeader">
      <div className="photo">
        <Photos urls={urls} setPhotoData={setPhotoData}/>
      </div>
      {showDescription ? <PhotoDesc data={data}/> : ''}
    </div>
  )
}

const NakalaCarousel = props => {
  const { nakalaurls, showDescription=true } = props;
  let urls = [];
  if (nakalaurls.length === 0) {
    urls = [ 'https://doi.org/10.34847/nkl.5b38j2bm' ]; // default url if none found.
  } else {
    urls = nakalaurls;
  }

  return <PhotoHeader urls={urls} showDescription={showDescription}/>
}

NakalaCarousel.propTypes = {
  /**
   * nakalaurls: An array of nakala/doi urls
   */
  nakalaurls: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * showDescription: show the right description panel (default true)
   */
  showDescription: PropTypes.bool,
}

export default NakalaCarousel
