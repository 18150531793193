import React from 'react'
import './Header.scss'

// zustand
import useStore from '../../lib/store'
import {shallow} from 'zustand/shallow'

// icons
import { FiHelpCircle } from 'react-icons/fi'
import { FiMenu } from 'react-icons/fi'

// i18n
import { useTranslation } from 'react-i18next';
//

const Header = () => {
  const { t } = useTranslation();
  const [ setRightPanel, setSidePanelOpened, setShowBurgerModal ] = useStore(state => [ state.setRightPanel, state.setSidePanelOpened, state.setShowBurgerModal], shallow)

  return (
    <div className="ComponentHeader">
      <header>
        <div className="header-title">
          <span className="header-title-part1">Arke</span>
          <span className="header-title-part2">Open</span>
        </div>
        <div className="header-search">
          <a
            href='/#'
            rel="noreferrer"
            onClick={(e) => {
              setSidePanelOpened('left');
              e.preventDefault()
            }}
          >
            {t('components.Header.Link.Search.label')}
          </a>
        </div>
        <div className="header-project">
          <a
            href='/#'
            rel="noreferrer"
            onClick={(e) => {
              setRightPanel({type: 'project', id: 'project'})
              e.preventDefault()
              }}
          >
            {t('components.Header.Link.Project.label')}
          </a>
        </div>

        <div className="header-help">
          <button onClick={(e) => {
            setRightPanel({type: 'help'})
            e.preventDefault()
          } }>
            <FiHelpCircle/>
          </button>
        </div>

        <div className="header-burger">
          <button onClick={(e) => {
            setShowBurgerModal(true);
            e.preventDefault()
          } }>
            <FiMenu/>
          </button>
        </div>
      </header>
    </div>
  )
}

export default Header
