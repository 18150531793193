import React from 'react'
//import PropTypes from 'prop-types'
import ShareButton from '../ShareButton/ShareButton'

import { GetDatabaseDetails } from '../../lib/queries/database'

import { useQuery } from '@apollo/client';

import Linkify from 'react-linkify';

import useStore from '../../lib/store'

// i18n
import { useTranslation } from 'react-i18next';
import { getInLang, getInLangWKey } from '../../lib/translate';
/** some translations "npm run extract-translations" : 'undefined','academic-work','contract','research_team','other'
 * t`arkeo.database.type.undefined`
 * t`arkeo.database.type.inventory`
 * t`arkeo.database.type.research`
 * t`arkeo.database.type.literary-work`
 *
 * t`arkeo.database.geographical_extent.undefined`
 * t`arkeo.database.geographical_extent.country`
 * t`arkeo.database.geographical_extent.continent`
 * t`arkeo.database.geographical_extent.international_waters`
 * t`arkeo.database.geographical_extent.world`
 *
 * t`arkeo.database.state.undefined`
 * t`arkeo.database.state.in-progress`
 * t`arkeo.database.state.finished`
 *
 * t`arkeo.database.context.undefined`
 * t`arkeo.database.context.academic-work`
 * t`arkeo.database.context.contract`
 * t`arkeo.database.context.research_team`
 * t`arkeo.database.context.other`
 */

import './PanelDataset.scss';
import Loader from '../Loader/Loader';
import MiniMap from '../MiniMap/MiniMap';
import Markdown from '../Markdown/Markdown'
import { yearToHuman } from '../../lib/year';

import NakalaCarousel from '../NakalaCarousel/NakalaCarousel';
import CloseButton from '../CloseButton/CloseButton';

const myLink = (decoratedHref, decoratedText, key) => {
  return (
    <a href={decoratedHref} key={key} target='_blank' rel="noreferrer">
      {decoratedText}
    </a>
  );
};

const PanelDataset = props => {
  const { t, i18n } = useTranslation();
  const { id } = props;

  const setRightPanel = useStore(state => state.setRightPanel);

  const database = useQuery(GetDatabaseDetails, {
    variables: {
      database_id: id,
    }
  }) // database = { loading, error, data }

  if (database.loading) return <div className="PanelDataset"><Loader /></div>
  if (database.error) return <div className="PanelDataset"><div className="Error">{database.error.message}</div></div>

  if (!database.data.ako_database || database.data.ako_database.length === 0)
    return <div className="PanelDataset"><div className="Error">Dataset not found</div></div>

  const db = database.data.ako_database[0];
  //console.log("dataset data !", db);

  const geojson = {
    "type": "FeatureCollection",
    "features": [{
      "type": "Feature",
      "geometry": db.geographical_extent_geom,
    }]
  };

  //console.log("geojson: ", geojson);
  const nakalaurls = [];
  db.illustrations.trim().split(/[, ]+/).forEach(url => url !== '' ? nakalaurls.push(url) : '')

  return (
    <div className="PanelDataset">
      <div className="TwoColumns">
        <div className="LeftColumn">
          <NakalaCarousel nakalaurls={nakalaurls} showDescription={false}/>
        </div>
        <div className="RightColumn">
          <MiniMap minimaptype="dataset" geojson={geojson}/>
        </div>
      </div>
      <CloseButton rp={{type: 'legend', id: 'other'}} />
      <div className="PanelDatasetContent">
        <div className="PanelDatasetHeader">
          <div className="HeaderTitle">{db.name}</div>
          <div className="HeaderButtons">
            <ShareButton />
          </div>
        </div>

        <div className="TwoColumns">
          <div className="LeftColumn">
            <h5>{t`components.PanelDataset.temporal.title`}</h5>
            <table className="Chronos">
              <tbody>
                <tr>
                  <th>{t`components.PanelDataset.temporal.begin.title`}</th>
                  <td className="date">{yearToHuman(db.start_date)}</td>
                  {/*
                  <td className="chrononame">{getInLang(db.chronologies[0].chronology_trs, i18n.language).name}</td>
                  <td><div className="chronocolor" style={{ backgroundColor: '#'+db.chronologies[0].color}}></div></td>
                  */}
                </tr>
                <tr>
                  <th>{t`components.PanelDataset.temporal.end.title`}</th>
                  <td className="date">{yearToHuman(db.end_date)}</td>
                  {/*
                  <td className="chrononame">{getInLang(db.chronologies.at(-1).chronology_trs, i18n.language).name}</td>
                  <td><div className="chronocolor" style={{ backgroundColor: '#'+db.chronologies.at(-1).color}}></div></td>
                  */}
                </tr>
              </tbody>
            </table>
            <div>
              <strong>{t`components.PanelDataset.chronology.title`}</strong> : {' '}
              <a
                href='/#'
                rel="noreferrer"
                onClick={
                  e => {
                    e.preventDefault();
                    setRightPanel({type: 'chronology', id: db.root_chronology_id})
                  }
                }
              >
                {db.chronology_root.chronology && db.chronology_root.chronology.chronology_trs ?
                getInLang(db.chronology_root.chronology.chronology_trs, i18n.language).name : ''}
              </a>
            </div>
          </div>
          <div className="RightColumn">
            <h5>{t`components.PanelDataset.geographical.title`}</h5>
            {db.database__continents.length > 0 ? (
              <div>
                <strong>{t`components.PanelDataset.continents.title`}</strong> : {' '}
                {db.database__continents.map(dc => getInLang(dc.continent.continent_trs, i18n.language).name).join(', ')}
              </div>
            ) : ''}
            {db.database__countries.length > 0 ? (
              <div>
                <strong>{t`components.PanelDataset.countries.title`}</strong> : {' '}
                {db.database__countries.map(dc => getInLang(dc.country.country_trs).name, i18n.language).join(', ')}
              </div>
            ) : ''}
            {getInLang(db.database_trs, i18n.language).geographical_limit}
          </div>
        </div>

        <hr />

        <div className="TwoColumns">
          <div className="LeftColumn">
            <div><strong>{t`components.PanelDataset.coordinator.title`}</strong> : {`${db.user.lastname} ${db.user.firstname}`}</div>
            <div><strong>{t`components.PanelDataset.authors.title`}</strong> : {db.database__authors.map(ug => `${ug.user.lastname} ${ug.user.firstname}`).join(', ')}</div>
            <div><strong>{t`components.PanelDataset.editors.title`}</strong> : {db.editor_url ? <a href={db.editor_url} target='_blank'>{db.editor}</a> : db.editor}</div>
            <div><strong>{t`components.PanelDataset.contributor.title`}</strong> : {db.contributor}</div>
            <div><strong>{t`components.PanelDataset.type.title`}</strong> : {t(`arkeo.database.type.${db.type}`)}</div>
            <div><strong>{t`components.PanelDataset.scale_resolution.title`}</strong> : {t(`arkeo.database.scale_resolution.${db.scale_resolution}`)}</div>
          </div>
          <div className="RightColumn">
            <div><strong>{t`components.PanelDataset.date-created.title`}</strong> : {new Date(db.created_at).toLocaleDateString(i18n.language)}</div>
            <div><strong>{t`components.PanelDataset.date-updated.title`}</strong> : {new Date(db.updated_at).toLocaleDateString(i18n.language)}</div>
            <div><strong>{t`components.PanelDataset.state.title`}</strong> : {t(`arkeo.database.state.${db.state}`)}</div>
            <div><strong>{t`components.PanelDataset.number_of_lines.title`}</strong> : {db.imports[0].number_of_lines}</div>
            <div><strong>{t`components.PanelDataset.number_of_sites.title`}</strong> : {db.imports[0].number_of_sites}</div>
          </div>
        </div>

        <hr />

        <div><strong>{t`components.PanelDataset.description.title`}</strong> : <div style={{whiteSpace: 'pre-wrap'}}><Markdown>{getInLang(db.database_trs, i18n.language).description}</Markdown></div></div>
        <div><strong>{t`components.PanelDataset.source_description.title`}</strong> : <Linkify componentDecorator={myLink}>{getInLangWKey(db.database_trs, "source_description", i18n.language, {emptyIsUndefined: true})}</Linkify></div>
        <div><strong>{t`components.PanelDataset.source_relation.title`}</strong> : <Linkify componentDecorator={myLink}>{getInLangWKey(db.database_trs, "source_relation", i18n.language, {emptyIsUndefined: true})}</Linkify></div>
        <div><strong>{t`components.PanelDataset.contexts.title`}</strong> : {db.database_contexts.map(c => t(`arkeo.database.context.${c.context}`)).join(', ')}</div>

        <hr />

        <strong>{t`components.PanelDataset.Citation.title`}</strong> : <Linkify componentDecorator={myLink}>{getInLang(db.database_trs, i18n.language).bibliography}</Linkify>

        <div>
          <strong>{t`components.PanelDataset.license.title`}</strong> : <a href={db.license.url} target="_blank" rel="noreferrer">{db.license.name}</a>
        </div>

      </div>
    </div>
  )
}

PanelDataset.propTypes = {}

export default PanelDataset
