import React from 'react'
//import PropTypes from 'prop-types'

// zustand
import useStore, { useSearchStore } from '../../lib/store'
import {shallow} from 'zustand/shallow'
//

// i18n
import { useTranslation } from 'react-i18next';
import { getInLang } from '../../lib/translate';
//

import ReactSwipe from 'react-swipe';
import { BsChevronCompactRight, BsChevronCompactLeft } from 'react-icons/bs'

import _ from 'underscore';

import Chronology from './Chronology'

import './ChronologySelector.scss'

const ChronologySelector = () => {
  const { i18n } = useTranslation();
  const chronologies = [...useStore(state => state.chronologies)]
    .sort((a,b) => getInLang(a.chronology_trs, i18n.language).name.localeCompare(getInLang(b.chronology_trs, i18n.language).name, i18n.language))
  const [
    selectedChronologyId,
    setSelectedChronologyId
  ] = useSearchStore(state => [
    state.selectedChronologyId,
    state.setSelectedChronologyId
  ], shallow);

  let reactSwipeEl;

  return (
    <div className="ChronologySelector">
      <ReactSwipe
        className="ChronologySelector"
        swipeOptions={{ continuous: false }}
        ref={el => (reactSwipeEl = el)}
      >
        { _.chunk(chronologies, 3).map((chronocolumn, colnum) => (
          <div key={colnum} className="ChronosColumn">
            {chronocolumn.map(chronology => (
              <Chronology
                key={chronology.id}
                chronology={chronology}
                selected={chronology.id === selectedChronologyId}
                onClick={() => setSelectedChronologyId(chronology.id)}
                />
            ))}
          </div>
        ))}
      </ReactSwipe>
      <button className="ButtonNext" onClick={() => reactSwipeEl.next()}><BsChevronCompactRight/></button>
      <button className="ButtonPrev" onClick={() => reactSwipeEl.prev()}><BsChevronCompactLeft/></button>
    </div>
  )
}

ChronologySelector.propTypes = {}

export default ChronologySelector
