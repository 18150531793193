import React from 'react'
//import PropTypes from 'prop-types'

// i18n
import { useTranslation } from 'react-i18next';

import useStore from '../../lib/store'
//import {shallow} from 'zustand/shallow'

const MapButtonLegend = props => {
  const setRightPanel = useStore(state => state.setRightPanel)

  const { t } = useTranslation();
  return (
    <button
      className='maplibregl-ctrl-zoom-in mapboxgl-ctrl-zoom-in MapButtonLegend'
      onClick={(e) => {
        setRightPanel({type: 'legend'})
        e.preventDefault()
      }}
    >
      <img alt="legend" src='/icons-maps/legend.svg'/>
      <div className="mapbuttontext">{t("components.MainMap.MapButtonLegend.label")}</div>
    </button>
  )
}

MapButtonLegend.propTypes = {}

export default MapButtonLegend
