import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import config from '../../config.json';
import maplibregl, { SourceFeatureState } from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import Map from 'react-map-gl';

// zustand
import useStore from '../../lib/store'
import { basemaps } from '../../lib/map/basemaps';

import './MainMap.scss'
import Loader from '../Loader/Loader';

// allow use of mapbox maps
import transformRequest from '../../lib/map/transformRequest';

import MyReactControl from './MyReactControl';
import MapButtonLayers from './MapButtonLayers';
import MapButtonLegend from './MapButtonLegend';

/**
 * MainMaps is our map object that simplify the use in react
 * @param {} props
 */

const ReactMap = props => {
  const { onMap, onMove, viewState } = props;

  const [ loaded, setLoaded ] = useState(false);

  const rmap = useRef(null);

  const firstInit = () => {
    if (rmap.current == null) return;
    const map = rmap.current.getMap();
    //setMapObject(map);
    //map.dragRotate.disable();
    //map.touchZoomRotate.disableRotation();

    /**
     * Add Scale Bar (echelle)
     */
    let scale1 = new maplibregl.ScaleControl({
      maxWidth: 100,
      unit: 'imperial'
    });
    map.addControl(scale1, 'bottom-right');
    //scale1.setUnit('metric');

        /**
     * Add Scale Bar (echelle)
     */
    let scale2 = new maplibregl.ScaleControl({
      maxWidth: 100,
      unit: 'metric'
    });
    map.addControl(scale2, 'bottom-right');
    //scale2.setUnit('metric');

    /**
     * add Buttons
     */
    map.addControl(new MyReactControl({
      element: <MapButtonLegend></MapButtonLegend>
    }), 'bottom-right');
    map.addControl(new MyReactControl({
      element: <MapButtonLayers></MapButtonLayers>
    }), 'bottom-right');
    map.addControl(new maplibregl.NavigationControl({
      showCompass: true,
    }), 'bottom-right');

    map.addControl(new maplibregl.AttributionControl({
      compact: false
    }), 'bottom-left');

    onMap(map);
  };

  return (
    <div className="map-container-container">
      <Map
        onLoad={firstInit}
        ref={rmap}
        className="map-container"
        mapLib={maplibregl}
        mapStyle={basemaps[useStore.getState().basemap].url}
        attributionControl={false}
        transformRequest={transformRequest}
        onMove={onMove}
        renderWorldCopies={false}
        {...viewState}
      />
    </div>
  )
}

ReactMap.propTypes = {
  /**
   * the manager function react element, will be called with props "map" (something like <Manager map={map}/>)
   */
  onMap: PropTypes.func.isRequired,
  /**
   * onMove event
   */
  onMove: PropTypes.func.isRequired,
  /**
   * viewState
   */
  viewState: PropTypes.object.isRequired,
}

export default ReactMap
