// react
import { Suspense, useEffect } from 'react'
import {
  Routes,
  Route,
} from "react-router-dom"
//

//import { StoreRoute } from './lib/storeroute';
import StoreRoute  from './components/StoreRoute/StoreRoute';

// css
import './App.css'
//

// components
import InitMainData from './components/InitMainData/InitMainData';
import Header from './components/Header/Header'
import Main from './components/Main/Main'
//import About from './components/About/About'
//import Boot from './components/Boot/Boot'
import Loader from './components/Loader/Loader'
import WelcomeModal from './components/WelcomeModal/WelcomeModal'
import BurgerModal from './components/BurgerModal/BurgerModal'
//

function App() {
  return (
    <Suspense fallback={<Loader fullHeight={true}/>}>
      <InitMainData>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<Main/>}/>
            {/*<Route path="/about" element={<About/>}/>*/}
            {/*<Route path="/boot" element={<Boot/>}/>*/}
          </Routes>
          <Header></Header>
          <WelcomeModal/>
          <BurgerModal/>
        </div>
        <StoreRoute/>
      </InitMainData>
    </Suspense>
  )
}

export default App;
