import React from 'react'
import PropTypes from 'prop-types'

// zustand
import useStore from '../../lib/store'
import {shallow} from 'zustand/shallow'

import { BsChevronCompactRight, BsChevronCompactLeft } from 'react-icons/bs'

// i18n
import { useTranslation } from 'react-i18next';
//

import './SidePanel.scss';

/**
 * SidePanel is the left or right side panel that can be opened
 * @param {*} props
 * @returns The SidePanel component
 */
const SidePanel = props => {
    const { t } = useTranslation();
    const { position, children } = props;
    const [ sidePanelOpened, setSidePanelOpened ] = useStore(state => [ state.sidePanelOpened, state.setSidePanelOpened], shallow)
    const show = sidePanelOpened === position;

    const toggleShow = () => {
        setSidePanelOpened(show ? 'none': position);
    }

  return (
    <React.Fragment>
        <div className={`SidePanel ${position}`+(show ? ' show' : '')}>
          {children}
          <button
            className="SidePanelHandle"
            onClick={toggleShow}
            title={position === 'left' ? t("components.SidePanel.left.handle.popup") : t("components.SidePanel.right.handle.popup")}
          >
            {position === 'left' ? <BsChevronCompactRight/> : <BsChevronCompactLeft/>}
          </button>
        </div>
    </React.Fragment>
  )
}

SidePanel.propTypes = {
    /**
     * position: string "left" or "right"
     */
    position: PropTypes.string.isRequired,
    /**
     * The node element that will be inside the SidePanel
     */
    children: PropTypes.node.isRequired,
}

export default SidePanel
