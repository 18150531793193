// react
import React, { useState } from 'react';
import PropTypes from 'prop-types';
//

// bootstrap
import Collapse from 'react-bootstrap/Collapse';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
//import Popover from 'react-bootstrap/Popover';
//

// icons
import { FaChevronDown  } from 'react-icons/fa';
//


import './FlatSelect.css';

/**
 * This is the custom FlatSelect component that is used for characs and maybe somewhere else.
 * It is a recursive Select menu
 * @returns
 */
function FlatSelect(props) {
  const [ opened, setOpened ] = useState(false);
  const { select, label, children, leaf=false, onSelect, selected=false, partiallySelected=null, unselectable=false, showRadio=false, disabled=false, pop=null } = props;
  const [ delayedOpened, setDelayedOpened ] = useState(false);

  const mySetSelected = (_sel) => {
    if (unselectable) mySetOpened(!opened);
    else if (onSelect) onSelect(_sel)
  }

  const mySetOpened = (_opened) => {
    setOpened(_opened);
    if (_opened) setDelayedOpened(true);
    else setTimeout(() => {
      setDelayedOpened(false);
    }, 300);
  }

  const mainbutton = (
    <button className={`label btn btn-secondary ${selected && !showRadio ? 'selected' : 'not-selected'}`} onClick={() => mySetSelected(!selected)}>
      {label}
      { showRadio ? (
        <div className={`radio ${selected ? 'selected' : 'not-selected'}`}/>
      ) : ''}
    </button>
  );

  return (
    <div className={`FlatSelect ${disabled ? 'disabled' : 'enabled'}`}>
      <div className={`FlatSelectSelect ${leaf ? 'leaf': 'notleaf'} ${showRadio ? 'show-radio' : ''}`}
        {...select}
      >
        {pop ? (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement='auto-end'
            overlay={pop}
          >
            {mainbutton}
          </OverlayTrigger>
        ) : mainbutton}
        { !leaf ? (
          <button
          className={`icon btn btn-secondary ${selected || partiallySelected ? 'selected' : 'not-selected'}`}
          onClick={() => { if (!disabled) mySetOpened(!opened)}}
        >
          <FaChevronDown
            className={`${opened && !disabled ? 'opened' : 'closed'}`}
          />
        </button>
        ) : ''}
      </div>
      <Collapse in={opened && !disabled} className="FlatSelectContent">
        <div>
          {children && delayedOpened ? children : ""}
        </div>
      </Collapse>
    </div>
  );
}

FlatSelect.propTypes = {
  /**
   * select div params
   */
  select: PropTypes.object,
  /**
   * The label displayed in the select
   */
  label: PropTypes.string,
  /**
   * children Element (may be one or more others flatselect)
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  /**
   * true if there is no children
   */
  leaf: PropTypes.bool,
  /**
   * onSelect is called when the selection change
   */
  onSelect: PropTypes.func,
  /**
   * selected: boolean, truc if selected
   */
  selected: PropTypes.bool,
  /**
   * partiallySelected: true for an openable only element
   */
  partiallySelected: PropTypes.bool,
  /**
   * unselectable: true for an openable only element
   */
  unselectable: PropTypes.bool,
  /**
   * showRadio: true if a radio button is displayed
   */
  showRadio: PropTypes.bool,
  /**
   * disabled: true to disable the input
   */
  disabled: PropTypes.bool,
  /**
   * pop: PopOver bootstrap element
   */
  pop: PropTypes.element,
};

export default FlatSelect;
