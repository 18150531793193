import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// zustand
import useStore from '../../lib/store'
import {shallow} from 'zustand/shallow'

// i18n
import { useTranslation } from 'react-i18next';
//

import { useSearchParams } from "react-router-dom";

import './WelcomeModal.scss';

const WelcomeModal = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  let paramsCount = 0;
  searchParams.forEach(() => {paramsCount++});

  const [
    showWelcomeModal,
    setShowWelcomeModal,
    setSidePanelOpened,
    setRightPanel
  ] = useStore(state => [
    state.showWelcomeModal,
    state.setShowWelcomeModal,
    state.setSidePanelOpened,
    state.setRightPanel
  ], shallow)

  const handleClose = () => setShowWelcomeModal(false);
  const handleSearch = () => {
    setShowWelcomeModal(false);
    setSidePanelOpened('left');
  }
  const handleProject = () => {
    setShowWelcomeModal(false);
    setRightPanel({type: 'project', id: 'project'});
  }


  return (
    <Modal size="xl" show={showWelcomeModal} onHide={handleClose} centered className="WelcomeModal">
      <Modal.Header>
        <Modal.Title>{t('components.WelcomeModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="presentation">{t('components.WelcomeModal.presentation')}</div>
        <div className="logos">
          <img src='/logos/ministere-recherche.png' alt="Ministère de l'enseignement supérieur et de la recherche"/>
          <img src='/logos/Signature_Universite_Strasbourg_Unistra2_Transparent 1.png' alt="Université de Strasbourg Unistra2"/>
          <img src='/logos/11_Logo UMR 1.png' alt='UMR'/>
          <img src='/logos/logo-misha-court 1.png' alt='Misha'/>
          <img src='/logos/logo-MASA.png' alt='MASA'/>
          <img src='/logos/logo huma-num 1.png' alt='Huma-Num'/>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {paramsCount > 0 ? (
          <React.Fragment>
            <Button className="big" variant="primary" onClick={handleClose}>
              {t('components.WelcomeModal.button.ok.label')}
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button className="big" variant="light" onClick={handleProject}>
              {t('components.WelcomeModal.button.project.label')}
            </Button>
            <Button className="big" variant="primary" onClick={handleSearch}>
              {t('components.WelcomeModal.button.search.label')}
            </Button>
          </React.Fragment>
        )
        }
      </Modal.Footer>
    </Modal>
  );
}

export default WelcomeModal;
