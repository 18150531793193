import { gql } from '@apollo/client';

export const GetShapefiles = gql`
query GetShapefiles {
  ako_shapefile(order_by: {id: asc}) {
    shapefile_trs {
      description
      citation
      name
      lang_isocode
    }
    id
    end_date
    start_date
  }
}
`

export const GetShapefilesByIds = gql`
query GetShapefilesByIds($ids: [Int!]) {
  ako_shapefile(where: {id: {_in: $ids}}) {
    id
    geojson
  }
}
`

export const GetFullShapefile = gql`
query GetFullShapefile($shapefile_id: Int!) {
  ako_shapefile(where: {id: {_eq: $shapefile_id}}) {
    declared_creation_date
    created_at
    updated_at
    start_date
    end_date
    editor
    editor_uri
    licenseByLicenseId {
      name
      url
      id
    }
    shapefile_trs {
      citation
      geographical_covering
      description
      name
      lang_isocode
      attribution
    }
    geojson
    shapefile__authors {
      user {
        firstname
        lastname
      }
    }
  }
}
`
