import React, { useState } from 'react'
//import PropTypes from 'prop-types'
import _ from 'underscore';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

// i18n
import { useTranslation } from 'react-i18next';

import useStore from '../../lib/store'
import {shallow} from 'zustand/shallow'

import { basemaps } from '../../lib/map/basemaps';

const LayersList = () => {
  const [ basemap, setBasemap ] = useStore(state => [ state.basemap, state.setBasemap], shallow)

  return (
    <div className="arkeolayers-list">
      {_.map(basemaps, (m, k) => {
        return (
          <OverlayTrigger
            key={k}
            trigger={["hover", "focus"]}
            placement='top'
            overlay={
              <Popover id={`popover-basemap`}>
                <Popover.Body>
                  {m.name}
                </Popover.Body>
              </Popover>
            }
          >
            <button
              onClick={() => setBasemap(k)}
              className={`arkeolayers-layer-button ${basemap === k ? 'selected' : ''}`}
              aria-label={m.name}
            >
              <img src={m.icon} alt={m.name} width="24" height="24"/>
            </button>
          </OverlayTrigger>
        )
      })}
    </div>
  )
}

const MapButtonLayers = () => {
  const { t } = useTranslation();
  const [ show, setShow ] = useState(false);

  return (
    <React.Fragment>
      <button
        className='maplibregl-ctrl-zoom-in mapboxgl-ctrl-zoom-in MapButtonLayers'
        onClick={() => setShow(!show)}
        title={t("components.MainMap.MapButtonLayers.label")}
        aria-label={t("components.MainMap.MapButtonLayers.label")}
      >
        <img alt="layers" src='/icons-maps/layers.svg'/>
      </button>
      <div className={`arkeolayers ${show ? 'opened' : 'closed'}`}>
        <LayersList />
      </div>
    </React.Fragment>
  )
}

MapButtonLayers.propTypes = {}

export default MapButtonLayers
