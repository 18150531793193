import { gql } from '@apollo/client';

export const GET_DATABASES_QUERY = gql`
  query GetDatabases($public: Boolean! = true) {
    ako_database(where: {public: {_eq: $public}, published: {_eq: true}}, order_by: {name: asc}) {
      name
      id
    }
  }
`;

export const GetDatabases = gql`
  query GetDatabases {
    ako_database(order_by: {name: asc}) {
      name
      id
    }
  }
`;

export const GetDatabasesAuthors = gql`
query GetDatabasesAuthors {
  ako_database__authors(distinct_on: user_id) {
    user_id
    user {
      firstname
      lastname
    }
  }
}
`

export const GetDatabasesEditors = gql`
query GetDatabasesEditors {
  ako_database(distinct_on: editor, order_by: {editor: asc}) {
    editor
  }
}
`

export const GetDatabaseDetails = gql`
query GetDatabaseDetails($database_id: Int!) {
  ako_database(where: {id: {_eq: $database_id}}) {
    contributor
    scale_resolution
    created_at
    declared_creation_date
    default_language
    editor
    editor_url
    end_date
    geographical_extent
    geographical_extent_geom
    id
    illustrations
    license {
      url
      name
    }
    name
    user {
      firstname
      lastname
    }
    database__authors {
      user {
        firstname
        lastname
      }
    }
    database__countries {
      country {
        country_trs {
          name
          lang_isocode
        }
      }
    }
    database__continents {
      continent {
        continent_trs {
          lang_isocode
          name
        }
      }
    }
    imports(order_by: {created_at: desc}, limit: 1) {
      number_of_lines
      number_of_sites
    }
    database_trs {
      bibliography
      context_description
      copyright
      description
      lang_isocode
      geographical_limit
      re_use
      source_description
      source_relation
      subject
    }
    start_date
    state
    type
    updated_at
    root_chronology_id
    chronology_root {
      chronology {
        chronology_trs {
          lang_isocode
          name
        }
      }
    }
    database_contexts(order_by: {id: asc}) {
      context
    }
  }
}
`
